.AuthorPageSearchBarMainArea {
    display: flex;
    flex-direction: column;
}

.AuthorPageSearchBarMainSection {
    width: 738.4px;
    height: 22.4px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 12px 12px 12px 16px;
    border-radius: 8px 0px 0px 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.50);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.AuthorPageSearchBarMainSectionStartSearch img {
    width: 24px;
    height: 24px;
}

.AuthorPageSearchBarInput {
    margin: 0;
}

.AuthorPageSearchBarInput input {
    width: 716px;
    border: none;
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.AuthorPageSearchBarMainSectionEndSearch {
    padding: 12px 24px;
    border-radius: 0px 8px 8px 0px;
    border: 0.5px solid rgba(0, 0, 0, 0.50);
    background: #403085;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    height: 22.4px;
    width: 22.4px;
    margin-top: -13px;
    margin-left: -5px;
    cursor: pointer;
}

.AuthorPageSearchBarInput input:focus {
    outline: none;
}