/* Add your existing CSS styles here */

/* Example styles for suggestion-box */
.suggestion-box {
    margin-top: 2px;
    margin-left: 12px;
    margin-right: -10px;
    font-family: 'work sans';
    font-size: 16px !important;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    max-height: 100px;
    /* Set maximum height for suggestions */
    overflow-y: auto;
    /* Enable vertical scrollbar if content exceeds max-height */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
}

.suggestion-box::-webkit-scrollbar {
    display: none;
}

.suggestion-box div {
    overflow: hidden;
    /* Hide any content that overflows the box */
    text-overflow: ellipsis;
    /* Display an ellipsis (...) to indicate text overflow */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the number of lines to 2 */
    -webkit-box-orient: vertical;
    height: 22px;
    /* width: 243.99px; */
}

.suggestion-box div {
    margin-bottom: 10px;
    /* Add space between individual suggestions */
}

.no-suggestion {
    margin-left: 12px;
    margin-right: -10px;
    text-align: left;
    margin-bottom: 5px;
    cursor: pointer;
    /* Change cursor to pointer on suggestion titles */
    color: #333;
}

.suggestion-box div {
    text-align: left;
    margin-bottom: 5px;
    cursor: pointer;
    /* Change cursor to pointer on suggestion titles */
    color: #333;
}

.suggestion-box p {
    font-size: 14px;
    color: #666;
}


/* ImageTextPage.css */


.image-overlay {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 100%;
    /* Ensure the image-overlay takes the full height */
}

.image-overlay img {
    /* border-radius: 16px; */
    width: 100%;
    height: 100%;
    /* Set the image to cover the full height of its container */
    object-fit: cover;
}

.text-container {
    /* border-radius: 16px; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Make the text container cover the full height of its parent */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
}

h1 {
    font-size: 36px;
}





/* @media screen and (max-width: 6000px) { */
.ws-stories-basket {
    flex: 0.82;
}

.ws-stories-ads {
    flex: 0.18;
}

.ws-stories-ads-block {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
}

.image-text-container {

    position: relative;
    width: 100%;
    height: 300px;
    /* height: 392px; */
    /* Set a fixed height for the image-text container */
    overflow: hidden;
    /* Clip any content that exceeds the height */
    display: flex;
    align-items: flex-start;
}

.ws-search-discover {
    padding-bottom: 8px;
    align-self: stretch;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: capitalize;
}

.ws-search-distinct strong {
    font-size: 32px;
}

.ws-search-distinct {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
}

.ws-search-whitebox {
    width: 750.4px;
    display: flex;
    padding: 2px 2px 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Border, #AFB1B6);
    background: #FFF;
}

.ws-search-whitebox>img {
    height: 24px;
    width: 24px;
}

.ws-search-input::placeholder {
    flex: 1 0 0;
    color: var(--text-secondary, #848484);
    font-family: poppins;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.ws-search-input:focus {
    border: none;
}

.ws-search-input {
    outline: none;
    display: flex;
    width: 708px;
    padding: 12px 12px 12px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: none;
    background: #FFF;
    font-size: 16px;
}

.ws-search-box {
    margin-left: 456px;
    margin-right: 454px;
}

@media screen and (max-width: 1400px) {
    .image-text-container {

        position: relative;
        width: 100%;
        height: 315px;
        /* Set a fixed height for the image-text container */
        overflow: hidden;
        /* Clip any content that exceeds the height */
        display: flex;
        align-items: flex-start;
    }

    .ws-search-distinct strong {
        font-size: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .ws-stories-basket {
        flex: 1;
    }

    .ws-stories-ads {
        display: none;
    }

    .ws-search-whitebox {
        margin-left: auto;
        /* width: 950px; */
        /* padding: 12px 12px 12px 16px; */
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid var(--Border, #AFB1B6);
        background: #FFF;

    }

    .ws-search-discover {
        align-self: stretch;
        color: var(--white, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.8px;
        text-transform: capitalize;
        padding-bottom: 8px;
    }

    .ws-search-distinct strong {
        font-size: 20px;
    }

    .ws-search-distinct {
        margin-left: 50px;
        margin-right: 50px;
        padding-bottom: 48px;
        color: var(--white, #FFF);
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-transform: capitalize;
    }

    .ws-search-input {
        outline: none;
        display: flex;
        width: 100%;
        padding: 12px 12px 12px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: none;
        background: #FFF;
        font-size: 16px;
    }

    .ws-search-box {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 950px) {
    .ws-stories-basket {
        flex: 1;
    }

    .ws-stories-ads {
        display: none;
    }

    .image-text-container {
        position: relative;
        width: 100%;
        height: 392px;
        /* Set a fixed height for the image-text container */
        overflow: hidden;
        /* Clip any content that exceeds the height */
        display: none;
        align-items: flex-start;
    }
}



@media screen and (max-width: 760px) {
    .ws-stories-basket {
        flex: 1;
    }

    .ws-stories-ads {
        display: none;
    }

    .image-text-container {
        position: relative;
        width: 100%;
        height: 392px;
        /* Set a fixed height for the image-text container */
        overflow: hidden;
        /* Clip any content that exceeds the height */
        display: none;
        align-items: flex-start;
    }
}