.AuthorPublicationsMainSection {
    width: 760px;
    height: 510px;
    padding: 40px;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.AuthorPublicationsHead {
    color: var(--text-heading, #252525);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* .AuthorPublicationsCards{
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap:24px;
} */
/* Target the container */
.AuthorPublicationsCards {
    margin-right: -40px;
    height: 100%;
    /* Set the desired height */
    overflow-y: auto;
    /* Change 'scroll' to 'auto' to only show scrollbar when necessary */
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    flex-wrap: wrap;
    scrollbar-width: thin;
    border-radius: 8px;
    /* Customize scrollbar width (not supported in Safari) */
    scrollbar-color: #403085 #EAEAEB;
    /* Customize scrollbar color (not supported in Safari) */
}

/* For Webkit-based browsers like Chrome */
.AuthorPublicationsCards::-webkit-scrollbar {
    width: 8px;
    /* Customize scrollbar width */
}

/* For Webkit-based browsers like Chrome - Track */
.AuthorPublicationsCards::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #EAEAEB;
    /* Set track color */
}

/* For Webkit-based browsers like Chrome - Handle */
.AuthorPublicationsCards::-webkit-scrollbar-thumb {
    background-color: #403085;
    /* Set thumb color */
    border-radius: 8px;
    /* Round the thumb */
}

/* For Webkit-based browsers like Safari */
.AuthorPublicationsCards::-webkit-scrollbar {
    border-radius: 8px;
    width: 8px;
    /* Customize scrollbar width */
}

/* For Firefox */
.AuthorPublicationsCards {
    border-radius: 8px;
    scrollbar-width: thin;
    /* Customize scrollbar width */
    scrollbar-color: #403085 #EAEAEB;
    /* Customize scrollbar color */
}

@media screen and (max-width: 950px) {

    /* .AuthorPublicationsMainSection {
        padding: 8px;
        width: 100%;
        background: #F7F5FF;
    } */
    .AuthorPublicationsMainSectionMob {
        width: 98%;
        height: 510px;
        padding: 8px;
        margin-bottom: 60px;
        /* border-radius: 16px; */
        background: #F7F5FF;
        /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .AuthorPublicationsCards {
        margin-right: 0px;
    }

    .AuthorPublicationsHead {
        font-size: 12px;
    }
}

@media screen and (max-width: 640px) {
    .AuthorPublicationsMainSectionMob {
        width: 96%;
        height: 510px;
        padding: 8px;
        /* border-radius: 16px; */
        background: #F7F5FF;
        /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}