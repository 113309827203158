/* YourStyles.css */

.mobile {
    position: relative;
}

.ws-mobile-header {
    display: flex;
    align-items: center;
    /* background-color: #333; */
    /* Adjust the background color as needed */
    /* color: #fff; */
    /* Adjust the text color as needed */
    /* padding: 10px; */
    padding-left: 24px;
    padding-right: 24px;

}

@media screen and (max-width: 950px) {
    .ws-header-logo {
        display: flex;
        align-items: center;
        /* Centers vertically */
    }

    .ws-header-logo img {
        margin-top: auto;
        margin-bottom: auto;
        width: 27px;
        /* Adjust the logo width */
        height: 27px;

    }

    .ws-mob-logo {
        flex-grow: 1;
        text-align: center;
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* Adjust the font size */
    }

    .headerspacemob3>img {
        height: 20px;
        width: 20px;
        margin-right: 12px;
    }

    .headerspacemob3 {
        z-index: 1000;
        font-size: 24px;
        cursor: pointer;
    }

    .hamburger-button {

        z-index: 1000;
        background: none;
        border: none;
        font-size: 24px;
        /* Adjust the font size */
        cursor: pointer;
    }

    .mobile-menu {
        z-index: 1000;
        /* Set a high z-index to ensure the menu appears above other elements */
        /* background-color: #333; */
        background-color: var(--white, #FFF);
        /* Adjust the background color */
        color: #fff;
        /* color: #000; */
        /* Adjust the text color */
        height: 100vh;
        position: fixed;
        top: 0;
        left: 81.5%;
        transform: translateX(-50%);
        /* Initially off the screen to the right */
        transition: transform 0.3s ease-in-out;
        /* Add a transition for smooth animation */
        width: 201px;
        /* width: 50%; */
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 0px 0px 32px 0px;
        /* border: 1px solid #000; */
    }

    .mobile-menu button {
        background: none;
        border: none;
        color: #000;
        /* color: #fff; */
        font-size: 18px;
        /* Adjust the font size */
        padding: 10px;
        text-align: left;
        cursor: pointer;
    }

    /* Additional styles for the close (X) button */
    .hamburger-button::after {
        content: '✕';
        /* The close icon */
        display: none;
        /* Initially hidden */
        font-size: 24px;
        /* Adjust the font size */
    }

    .mobile-menu.active {
        display: flex;

    }

    .mobile-menu.active+.headerspacemob3 .hamburger-button::after {
        display: block;
        /* Display the close (X) icon when the menu is active */
    }
}