.footer {
    justify-content: space-between;
    padding: 0px 40px;
    /* display: flex; */
    height: 267px;
    flex-shrink: 0;
    background: #BDBFE0;
}

.footer1 {
    display: flex;
    flex: 1;
    padding: 38px 0px 30px 0px;
}

.footer-text-logo {
    display: flex;
}

.footer-text-logo img {
    height: 58px;
    width: 58px;
}

.web-name {
    margin-top: 20px;
    margin-bottom: auto;
    padding-left: 16px;
    width: max-content;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.footer-SM {
    display: flex;
    margin-top: 20px;
}

.middle {
    border-left: 2px solid #000;
    /* Adjust color and thickness as needed */
    height: 58px;
    /* Adjust height as needed */
    margin: 0 20px;
    /* Adjust margin to create space between the elements */
}

.footerlast {
    margin-left: auto;

}

.footerbox {
    margin-left: auto;
    margin-right: auto;
    padding: 76px 0px;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;

}

.ws-footer-heading {
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ws-footer-para-kushmanda {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    cursor: pointer;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-footer-para {
    margin-top: auto;
    margin-bottom: auto;
    /* padding-bottom: 8px; */
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-footer-para-btn {
    margin-top: auto;
    margin-bottom: auto;
    width: max-content;
    cursor: pointer;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer2 {
    display: flex;
    margin-left: auto;
    /* padding-right: 40px; */
}

.footer3 {
    padding-right: 40px;
}

.ws-footer-heading-follow {
    margin-top: -4px;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.footer4 {
    display: flex;
    margin-right: auto;
    text-align: center;
}

.footer6 {
    border-bottom: 2px solid black;
    padding-top: 25px;
}

.footer5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer7 {
    display: flex;
    gap: 102px
}

.footerend {
    display: flex;
    margin-top: 28px;
    margin-bottom: 37px;
}

.footercorner {
    margin-left: auto;
    display: flex;
    gap: 16px;
}

.social-media {
    display: flex;
    margin-top: -3px;

}

.social-logo>img {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
    object-fit: cover;
    height: 24px;
    width: 24px;
}

.play>img {
    display: block;
    cursor: pointer;
    margin-top: 9px;
    width: 140px;
    height: 40px;
}

/* @media screen and (max-width: 2900px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 400px;
        padding-right: 400px;
        justify-content: space-between;
        width: 100%;

    }
}

@media screen and (max-width: 2600px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 300px;
        padding-right: 300px;
        justify-content: space-between;
        width: 100%;

    }
}

@media screen and (max-width: 2000px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 200px;
        padding-right: 200px;
        justify-content: space-between;
        width: 100%;

    }
}

@media screen and (max-width: 1800px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 150px;
        padding-right: 150px;
        justify-content: space-between;
        width: 100%;

    }
}

@media screen and (max-width: 1600px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 100px;
        padding-right: 100px;
        justify-content: space-between;
        width: 100%;

    }
}

@media screen and (max-width: 1490px) {
    .footerbox {
        padding: 76px 0px;
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
        width: 100%;

    }

    .web-name {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .footer1>img {
        margin-right: 16px;
        margin-top: auto;
        margin-bottom: auto;
        height: 46px;
        width: 46px;
    }
} */
@media screen and (max-width: 1350px) {
    .web-name {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .footer7 {
        display: flex;
        gap: 12px
    }
}

@media screen and (max-width: 1200px) {}

/* @media screen and (max-width: 1200px) {
    .footer {
        display: flex;
        height: 318px;
        flex-shrink: 0;
        background: #BDBFE0;
    }

    .footer6 {
        flex: 0.05;
    }

    .footer1 {

        display: flex;
        padding: 130px 0px;
    }
    .footer1>img {
        margin-right: 16px;
        height: 46px;
    }

    .footer2 {
        margin-top: 40px;
        margin-left: auto;
        flex: 0.30;
    }

    .footer3 {
        margin-right: auto;
        margin-top: 40px;
        flex: 0.30;
    }

    .footer4 {
        display: none;
        margin-top: 40px;
        flex: 0.22;
    }

    .footer5 {
        margin-top: 40px;
        flex: 0.30;
    }

    .social-media {
        display: flex;
    }

    .social-logo>img {
        object-fit: cover;
        margin-right: 4px;
        height: 20px;
    }

    .play>img {
        margin-left: 15px;
        height: 48px;
        width: auto;
    }

    .web-name {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .ws-footer-para {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-footer-para-kushmanda {
        cursor: pointer;
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-footer-para-btn {
        cursor: pointer;
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-footer-heading {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .ws-footer-heading-follow {
        padding-left: 20px;
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .play {
        margin-left: 0px;
    }
} */

@media screen and (max-width: 950px) {
    .footer {
        display: flex;
        height: 318px;
        flex-shrink: 0;
        background: var(--black, #000);
    }

    .footer6 {
        flex: 0.05;
    }

    .footer1 {
        margin-top: 125px;
        flex: 0.40;
        display: flex;
    }

    .footer1>img {
        margin-right: 5px;
        margin-top: 10px;
        height: 45px;
    }

    .footer2 {
        display: none;
        margin-top: 40px;
        flex: 0.30;
    }

    .footer3 {
        margin-top: 40px;
        flex: 0.25;
    }

    .footer4 {
        display: none;
        margin-top: 40px;
        flex: 0.22;
    }

    .footer5 {
        margin-top: 40px;
        flex: 0.20;
    }

    .social-media {
        display: flex;
    }

    .social-logo>img {
        object-fit: cover;
        margin-right: 4px;
    }

    .play>img {
        margin-left: -50px;
        height: 80px;
    }

    .web-name {
        color: var(--white, #FFF);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .ws-footer-para {
        color: var(--white, #FFF);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-footer-para-btn {
        cursor: pointer;
        color: var(--white, #FFF);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-footer-heading {
        color: var(--white, #FFF);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .ws-footer-heading-follow {
        color: var(--white, #FFF);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    /* .rights {
        text-align: center;
        z-index: 0;
        set a lower z-index
        position: relative;
        padding-bottom: 16px;
        padding-top: 16px;
        gap: 10px;
    } */

    .rights {
        background: #FFF;
        width: 100%;
        position: fixed;
        bottom: 0;
        text-align: center;
        z-index: 0;
        padding-bottom: 16px;
        padding-top: 16px;
        gap: 10px;

        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 240% */
        letter-spacing: 0.2px;
    }

    .rights-hide {
        display: none;
    }
}