.top-backbtn {
    flex: 0.09;
    height: 17vh;
    /* background-color: aqua; */
}

.top-middle {

    flex: 0.7;
    display: flex;
    height: 17vh;
    /* background-color: blueviolet; */
}

.view-title {
    margin-top: 3vh;
    flex: 1;
    height: 17vh;
    /* background-color: red; */
}

.top-share {
    margin-top: 3vh;
    bottom: 0;
    right: 0;
}

.webshare {
    /* margin-left: 550px; */
    background-color: aqua;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 999;
    /* Ensure it's above other elements */
}

.shareOpt {
    padding-top: 15px;
    padding-bottom: 27px;
    padding-left: 16px;
    padding-right: 57px;

    background-color: #FFF;
    box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    position: absolute;
    margin-left: -200px;
    z-index: 1000;
    /* Higher z-index value */

}

.shareOptflex {
    display: flex;

}

.share-link {
    padding: 8px 8px 8px 8px;
    border-radius: 8px;
    border: 1px solid #D4CBCB;
    display: flex;
}

.link-copy {
    text-decoration: none;
    color: var(--white, #FFF);
    padding-right: 16px;
    padding-left: 16px;
    /* padding: 8px 16px; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0; */
    border-radius: 4px;
    background: var(--Primary, #2F5FE3);
}

.link-img>img {
    height: 24px;
}

.whatsapp-img {
    padding-right: 24px;
    padding-bottom: 20px;
    display: flex;
    /* Set container as a flex container */
    justify-content: center;
    /* Horizontally center the image */
    align-items: center;
    /* Vertically center the image */
}

.text-wbs {
    padding-left: 24px;
    padding-right: 24px;
    /* font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; */
}

.whatsapp-img>img {
    height: 40px;
}

.sharebtn>img {
    height: 80px;
}