.AuthorFollowerCardMainSection {
    padding: 16px;
    width: 293px;
    height: 72px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AuthorFollowerCardImage img {
    cursor: pointer;
    width: 72px;
    height: 72px;
    border-radius: 100px;
}

.AuthorFollowerCardInfo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.AuthorFollowerCardName {
    color: #000;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.AuthorFollowerCardAuthor {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 950px) {
    .AuthorFollowerCardMainSection {
        width: 250px;
    }

    .AuthorFollowerCardName {
        font-size: 16px;
    }

    .AuthorFollowerCardAuthor {
        font-size: 12px;
    }


}