.ws-stories {
    display: flex;
    min-height: 100vh;
    /* Ensure the container takes at least the full viewport height. */
}

.ws-stories-storiescount {
    padding-left: 24px;
    padding-top: 24px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 120% */
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.other-sortby-name {
    margin-left: auto;
}

.other-sortby-name img {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.sortbyarrow {
    margin-left: auto;
}

.sortbyarrow img {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.other-sortby-name {
    margin-top: -51px;
    width: 179px;
    display: flex;
    padding: 12px 12px 12px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: #F7F5FF;
    /* box-shadow:
        -1px -1px 0 var(--text-heading, #252525),
        1px -1px 0 var(--text-heading, #252525),
        0 -0.5px 0 var(--text-heading, #252525),
        1px 1px 0 var(--text-heading, #252525); */
    border-top: 1px solid var(--text-heading, #252525);
    border-right: 1px solid var(--text-heading, #252525);
    border-bottom: 0.5px solid var(--text-heading, #252525);
    border-left: 1px solid var(--text-heading, #252525);
    /* Adjust the values as needed */

    /* box-shadow: 0 0 0 1px var(--text-heading, #252525); */
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    border-radius: 8px 8px 0px 0px;
}

.selected-sortby img {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.selected-sortby {
    margin-left: auto;

    margin-top: -51px;
    width: 179px;
    display: flex;
    padding: 12px 12px 12px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    background: #F7F5FF;
    border-top: 1px solid var(--text-heading, #252525);
    border-right: 1px solid var(--text-heading, #252525);
    border-bottom: 0.5px solid var(--text-heading, #252525);
    border-left: 1px solid var(--text-heading, #252525);
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    border-radius: 8px 8px 8px 8px;
}

.ws-stories-catsortby img {
    margin-left: -10px;
    cursor: pointer;
    display: block;
    width: 97px;
    height: 85px;
}

.ws-stories-catsortby-text {
    border-radius: 8px;
    box-shadow: 0 0 0 1px var(--text-heading, #252525);
    width: 179px;
    /* width: 92px; */
    margin-top: -51px;
    display: flex;
    padding: 12px 12px 12px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    color: var(--normal, #19191B);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.ws-stories-catsortby-text img {
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
}

.dropdown-options {
    /* position: relative; */
    z-index: 100;
    position: absolute;
    background: white;
    /* padding: 12px; */
    color: var(--normal, #19191B);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid var(--text-heading, #252525);
    border-right: 1px solid var(--text-heading, #252525);
    border-bottom: 1px solid var(--text-heading, #252525);
    border-left: 1px solid var(--text-heading, #252525);
    /* Add the following styles for hover effect */
    transition: background 0.3s ease, border-radius 0.3s ease;
}

.selectdot img {
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: block;
}

.dropdown-options div {
    cursor: pointer;
    display: flex;
    gap: 8px;
    /* Common styles for each option */
    padding: 16px;
    /* Add other styles as needed */
}

.dropdown-options img {
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: block;
}

.dropdown-options div:hover {
    border-radius: 8px;
    background: #F7F5FF;
    /* Add other styles for hover effect */
}

.ws-stories-sortby>img {
    cursor: pointer;
    width: 129px;
    height: 85px;
}

.ws-stories-sortby-img {
    margin-top: 4px;
}

.ws-stories-sortby-img img {
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: -4px;
    width: 129px;
    height: 85px;
}

.ws-stories-category-text {
    width: 130px;
    margin-top: -10px;
    display: flex;
    padding: 12px 12px 12px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    /* border: 1px solid #403085; */
    background: #403085;
    cursor: pointer;

    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.other-class-name img {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.other-class-name {
    margin-top: -10px;
    width: 130px;
    display: flex;
    padding: 12px 12px 12px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    /* border: 1px solid #403085; */
    background: #403085;
    cursor: pointer;

    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    border-radius: 8px 8px 0px 0px;
}

.ws-stories-category-cat {
    cursor: pointer;
    width: 151px;
    height: 47px;
}

.ws-stories-category-animatedcat img {
    display: block;
    cursor: pointer;
    width: 151px;
    height: 60px;
}

.ws-stories-category-text img {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.ws-stories-category>img {
    cursor: pointer;
    width: 151px;
    height: 85px;
}

/* Styles for category list and subcategories */
.category-list {
    /* position: relative; */
    z-index: 100;
    position: absolute;
    /* top: 100%; */
    padding-left: 24px;
    width: 140px;
    /* Position the list below the category section */
    /* left: 0; */
    z-index: 100;
    border-radius: 0px 0px 8px 8px;
    /* background: #000; */
    background: var(--white, #FFF);
    padding: 0px 9px 0px 9px;
    /* padding: 10px; */
    /* Padding inside the list */
}

.category-item {
    margin-left: -10px;
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #FFF;
    flex: 1 0 0;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Smooth transition for hover effect */

    /* Hover effect */
    &:hover {
        /* background-color: #f5f5f5; */
        /* background-color: #403085; */
        margin-left: -10px;
        margin-right: -8.5px;
        background-color: var(--primary-subtle, #F7F5FF);
        /* Change background color on hover */
    }
}

.subcategory-list {
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 200;
    /* width: 140px; */
    background: var(--primary-subtle, #F7F5FF);
    /* background-color: #fff; */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
    /* padding: 10px; */
    /* padding: 0px 9px 0px 9px; */
}

.category-item:hover .subcategory-list {
    display: block;
}

.subcategory-item {
    flex: 1 0 0;
    color: var(--text-heading, #252525);

    /* label large */
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
    padding: 12px;
    cursor: pointer;
    width: max-content;
    transition: background-color 0.3s ease;
}

.subcategory-list:hover {
    /* padding: 10px; */
}

.subcategory-item:hover {
    width: max-content;
    background-color: #e0dede;
    border-radius: 8px;
    width: calc(100% - 24px);
    /* Ensure the width remains consistent on hover */
    /* padding: 12px; */

}


.ws-stories-text {
    flex: 0.9;
}

.ws-stories-img {
    flex: 0.1;
    height: auto;
    width: 100%;
}

.ws-stories-box {
    cursor: pointer;
    padding-left: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    padding-top: 24px;
}

.ws-stories-btn {
    display: flex;
    padding-right: 0px;
    padding-bottom: 16px;
    border-bottom: 1px solid #D9D9D9;
    margin-left: 24px;
}

.ws-stories-box-text {
    display: flex;
}

.ws-stories-corner-left {
    margin-right: auto;
    /* Pushes this corner to the left */
}

.ws-stories-corner-left-cat {
    margin-right: auto;
    margin-bottom: 8px;
    /* Pushes this corner to the left */
}

.ws-stories-img>img {
    display: block;
    object-fit: cover;
    border-radius: 8px;
    height: 230px;
    width: 230px;
    aspect-ratio: auto 230/230;
}

.ws-stories-title-img {
    flex: 1;
    display: flex;
    /* padding-bottom: 8px; */

}

.ws-stories-box-title {
    flex: 0.9;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;

}

.ws-stories-img-hsb {
    /* flex: 0.1; */
    display: flex;
    margin-left: auto;
    padding-top: 10px;
    justify-content: flex-end;
    align-items: center;
}

.ws-stories-img-hsb-new {
    flex: 0.1;
    margin-left: auto;
    display: flex;
    padding-top: 10px;
}

.ws-new-tag {
    padding-right: 16px;
}

.ws-new-tag img {
    display: block;
    width: 88px;
    height: 28px;
}

.ws-heart>img {
    padding-right: 24px;
    width: 29.395px;
    height: 28px;
}

.ws-share>img {
    padding-right: 24px;
    width: 28px;
    height: 28px;
}

.ws-bookmark>img {
    padding-right: 16px;
    width: 22.573px;
    height: 28px;
}

.ws-stories-box-auth-date {
    display: flex;
    /* padding-bottom: 16px; */
}

.ws-stories-box-author {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-stories-box-date {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-stories-box-summary {
    /* padding-bottom: 16px; */
    color: #4E4E4E;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.ws-stories-box-summary {
    /* margin-top: auto;
    margin-bottom: auto; */
    margin-top: 45px;
    margin-bottom: 45px;
    /* padding-top: 60px;
    padding-bottom: 60px; */
}

.ws-stories-box-summary {
    overflow: hidden;
    /* Hide any content that overflows the box */
    text-overflow: ellipsis;
    /* Display an ellipsis (...) to indicate text overflow */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the number of lines to 2 */
    -webkit-box-orient: vertical;
    height: 50px;
    /* width: 243.99px; */
}

.ws-stories-box-viewbox {
    display: flex;
    padding-right: 16px;
}

.ws-story-view {
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-story-viewtext {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-story-box-viewrate {
    /* margin-top: auto; */
    /* padding-top: 84px; */
    display: flex
}

.ws-stories-box-ratebox {
    display: flex;
}

.ws-story-rate {
    padding-right: 8px;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-story-ratetext {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}




.ws-stories-pagination {
    border-bottom: 1px solid #D9D9D9;
    padding-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 40px;
}

.ws-stories-pagination-box {
    padding-bottom: 40px;
    display: flex;
    text-align: center;
    align-items: center;

}

.page-number {
    color: var(--text-heading, #252525);
    border: none;
    background: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.prev-button {
    display: flex;
    width: 85px;
    height: 35px;
    /* padding: 12px 12px; */
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: none;
    background: white;
    border: 1px solid var(--Border, #AFB1B6);

    color: var(--black, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.next-button {
    margin-left: 20px;
    display: flex;
    background: white;
    width: 85px;
    height: 35px;
    padding: 12px 10px 10px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--Border, #AFB1B6);

    color: var(--black, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.next-button img {
    height: 20px;
    width: 20px;
}



.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Other styles for your pagination container */
}

.page-count {
    /* Styles for the "Page X of Y" to align it to the left */
    padding-left: 24px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.pagination-controls {
    flex: 1;
    /* Take remaining space */
}

.pagination-center {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Additional styles for center-aligned elements */
}

.page-numbers {
    display: flex;
    gap: 5px;
    /* Adjust spacing between page numbers */
    /* Additional styles for page numbers */
}

.page-number {
    margin-right: 12px;
    margin-left: 12px;
    cursor: pointer;
}

.page-number.active {
    cursor: pointer;
}

.prev-button {
    margin-right: 20px;
    cursor: pointer;
}

.prev-button img {
    height: 20px;
    width: 20px;
}

.next-button {
    cursor: pointer;
}



.page-number:hover,
.prev-button:hover,
.next-button:hover {
    cursor: pointer;
    background-color: #eee;
    border-radius: 8px;
}


.page-number.active {
    /* background-color: #007bff; */
    color: #FC0;
}

.page-number.active img {
    display: block;
    height: 22px;
    width: 22px;
}

/* CSS */
.pop-up-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.88);
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    /* Ensure it's above other content */
}

.pop-up-box {
    background: white;
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
    text-align: center;
    position: relative;
}

.loading {
    display: flex;
}

.load {
    display: flex;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.load_image>img {
    margin-left: 5px;
    height: 50px;
}

.load_gif img {
    /* margin-left: 5px; */
    height: 160px;
    width: 271px;
}

.loading {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    min-height: 100vh;
    /* Set the height of the container to the full viewport height */
}

.loading img {
    height: 150px;
    width: 271px;
}

/* Style your "Login first" message text as needed */


@media screen and (max-width: 1200px) {

    .ws-stories-box {
        /* border: 1px solid var(--Border, #AFB1B6); */
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        padding-top: 24px;
    }

    .ws-stories-storiescount {
        padding-left: 24px;
        color: var(--text-primary, #474747);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .ws-stories-btn {

        display: flex;
        margin-right: 24px;
        padding-right: 0px;
        padding-bottom: 8px;
        border-bottom: 1px solid black;
        margin-left: 24px;
    }
}

@media screen and (max-width: 950px) {
    .ws-stories-box-align-bottom {
        padding-bottom: 80px;
    }

    .ws-stories-box {
        border-bottom: 1px solid var(--Border, #AFB1B6);
        margin-left: 16px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 16px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        padding-top: 16px;
    }

    .ws-stories-storiescount {
        padding-top: 16px;
        padding-left: 16px;
        color: var(--text-primary, #474747);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 120% */
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    .pagination {
        width: 100%;
        background: #FFF;
        bottom: 7%;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* Other styles for your pagination container */
    }

    .ws-stories-pagination {
        position: fixed;
        bottom: 0;
        border-bottom: none;
        padding-top: 8px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 16px;
    }

    .ws-stories-pagination-box {
        position: fixed;
        bottom: 0;
        padding-bottom: 0px;
        display: flex;
        text-align: center;
        align-items: center;
    }

    .ws-stories-btn {
        display: flex;
        align-items: center;
    }

    .ws-stories-corner-left {
        margin-right: auto;
        /* Pushes this corner to the left */
    }

    .ws-stories-category,
    .ws-stories-sortby {
        background: none;
        border: none;
        cursor: pointer;
    }

    .page-number {
        margin-right: 0px;
        margin-left: 0px;
        cursor: pointer;
    }

    .ws-stories-category>img {
        height: 16px;
        width: 16px;
    }

    .ws-stories-sortby>img {
        margin-left: 4px;
        margin-right: 4px;
        height: 16px;
        width: 16px;
    }

    .ws-stories-btn {
        display: flex;
        border: none;
        padding-right: 0px;
        padding-bottom: 0px;
        margin-left: 10px;
        margin-right: 16px;
    }

    .ws-stories-category {
        margin-right: 16px;
        background-color: white;
        display: flex;
        padding: 0px 12px 0px 16px;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: none;
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 200% */
        letter-spacing: 0.2px;
    }

    .ws-stories-sortby {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 0.2px;
    }

    .ws-stories-category>img {
        height: 16px;
        width: 16px;
    }

    .ws-stories-img-hsb {
        margin-top: -5px;
        flex: 0.1;
        display: flex;
    }

    .ws-heart>img {
        padding-right: 8px;
        width: 16.797px;
        height: 16px;
    }

    .ws-share>img {
        padding-right: 8px;
        width: 16px;
        height: 16px;
    }

    .ws-bookmark>img {
        padding-right: 8px;
        width: 12.899px;
        height: 16px;
    }

    .ws-stories-box-auth-date {
        display: flex;
        padding-bottom: 8px;
    }

    .ws-stories-title-img {
        display: flex;
        justify-content: space-between;
        padding-bottom: 4px;

    }

    .ws-stories-img>img {
        object-fit: cover;
        /* margin-top: -10px; */
        align-self: stretch;
        aspect-ratio: auto 96/100;
        height: 100px;
        width: 96px;
    }

    .ws-stories-box-title {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .ws-stories-box-title {
        white-space: nowrap;
        /* Prevent text from wrapping to the next line */
        overflow: hidden;
        /* Hide any content that overflows the box */
        text-overflow: ellipsis;
        /* Display an ellipsis (...) to indicate text overflow */
        /* max-width: 100%; */
        /* Set a maximum width to ensure proper ellipsis behavior */

        /* height: 44px; */
        width: 22px;
    }

    .ws-stories-box-summary {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .ws-stories-box-summary {
        padding-bottom: 8px;
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-box-summary {
        overflow: hidden;
        /* Hide any content that overflows the box */
        text-overflow: ellipsis;
        /* Display an ellipsis (...) to indicate text overflow */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Limit the number of lines to 2 */
        -webkit-box-orient: vertical;
        height: 20px;
        /* width: 243.99px; */
    }

    .ws-stories-box-author {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-box-date {
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-box-view-rate {
        display: flex;
    }

    .ws-stories-box-viewbox {
        display: flex;
        margin-top: 5px;
        /* margin-left: 24px; */
        padding-right: 0px;
    }

    .ws-story-view {
        color: var(--text-heading, #252525);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

    }

    .ws-story-viewtext {
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-box-ratebox {
        display: flex;
        margin-top: 5px;
        margin-left: 24px;
        padding-right: 0px;
    }

    .ws-story-box-viewrate {
        padding-bottom: 0px;
        display: flex
    }

    .ws-story-rate {
        color: var(--text-heading, #252525);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-story-ratetext {
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-box-category {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--theme-funschool-02, #FFFDD9);
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .ws-stories-box-moral {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--theme-funschool-02, #FFFDD9);
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
    }

    .prev-button {
        color: var(--text-primary, #474747);
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 300% */
        letter-spacing: 0.2px;
        background-color: #FFF;
        border: none;
    }

    .page-number:hover,
    .prev-button:hover,
    .next-button:hover {

        cursor: pointer;
        background-color: white;
        border-radius: 8px;
    }

    .next-button {
        width: 26px;
        height: 16px;
        margin-left: 16px;
        border: none;
        padding: 0px 0px 0px 0px;
        color: var(--text-primary, #474747);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 240% */
        letter-spacing: 0.2px;
    }

    .next-button img {
        display: none;
        height: 16px;
        width: 16px;
    }

    .prev-button img {
        display: none;
        height: 24px;
        width: 24px;
    }

    .page-count {
        color: var(--text-primary, #474747);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .page-number {
        color: var(--text-secondary, #848484);
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .page-number.active img {
        display: block;
        height: 12px;
        width: 12px;
    }

    /* Styles for category list and subcategories */
    .category-list {
        /* position: relative; */
        z-index: 100;
        position: absolute;
        left: 18px;
        top: 40px;
        /* top: 100%; */
        padding-left: 24px;
        width: 140px;
        /* Position the list below the category section */
        /* left: 0; */
        z-index: 100;
        border-radius: 0px 0px 8px 8px;
        /* background: #000; */
        background: var(--white, #FFF);
        padding: 0px 9px 0px 9px;
        /* padding: 10px; */
        /* Padding inside the list */
    }

    .category-item {
        margin-left: -10px;
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        background: #FFF;
        flex: 1 0 0;
        color: var(--text-heading, #252525);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.2px;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        /* Smooth transition for hover effect */

        /* Hover effect */
        &:hover {
            /* background-color: #f5f5f5; */
            /* background-color: #403085; */
            margin-left: -10px;
            margin-right: -8.5px;
            background-color: var(--primary-subtle, #F7F5FF);
            /* Change background color on hover */
        }
    }

    .subcategory-list {
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 200;
        /* width: 140px; */
        background: var(--primary-subtle, #F7F5FF);
        /* background-color: #fff; */
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 10px;
    }

    .category-item:hover .subcategory-list {
        display: block;
    }

    .subcategory-item {
        flex: 1 0 0;
        color: var(--text-heading, #252525);

        /* label large */
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        letter-spacing: 0.2px;
        padding: 12px;
        cursor: pointer;
        width: max-content;
        transition: background-color 0.3s ease;
    }

    .subcategory-item:hover {
        background-color: #f5f5f5;
    }

    /* sortby */
    .dropdown-options {
        /* position: relative; */
        z-index: 100;
        right: 30px;
        position: absolute;
        background: white;
        /* padding: 12px; */
        color: var(--normal, #19191B);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
        border-radius: 0px 0px 8px 8px;
        border-top: 1px solid var(--text-heading, #252525);
        border-right: 1px solid var(--text-heading, #252525);
        border-bottom: 1px solid var(--text-heading, #252525);
        border-left: 1px solid var(--text-heading, #252525);
        /* Add the following styles for hover effect */
        transition: background 0.3s ease, border-radius 0.3s ease;
    }

    .selectdot img {
        cursor: pointer;
        height: 24px;
        width: 24px;
        display: block;
    }

    .dropdown-options div {
        display: flex;
        gap: 8px;
        /* Common styles for each option */
        padding: 16px;
        /* Add other styles as needed */
    }

    .dropdown-options img {
        cursor: pointer;
        height: 24px;
        width: 24px;
        display: block;
    }

    .dropdown-options div:hover {
        border-radius: 8px;
        background: #F7F5FF;
        /* Add other styles for hover effect */
    }

    .dropdown-options-sortby {
        /* position: relative; */
        z-index: 100;
        top: 35px;
        right: 12px;
        position: absolute;
        background: white;
        /* padding: 12px; */
        color: var(--normal, #19191B);
        font-family: Work Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
        border-radius: 0px 0px 8px 8px;
        border-top: 1px solid var(--text-heading, #252525);
        border-right: 1px solid var(--text-heading, #252525);
        border-bottom: 1px solid var(--text-heading, #252525);
        border-left: 1px solid var(--text-heading, #252525);
        /* Add the following styles for hover effect */
        transition: background 0.3s ease, border-radius 0.3s ease;
    }

    .dropdown-options-sortby div {
        cursor: pointer;
        display: flex;
        gap: 8px;
        /* Common styles for each option */
        padding: 16px;
        /* Add other styles as needed */
    }

    .dropdown-options-sortby img {
        cursor: pointer;
        height: 24px;
        width: 24px;
        display: block;
    }

    .dropdown-options-sortby div:hover {
        border-radius: 8px;
        background: #F7F5FF;
        /* Add other styles for hover effect */
    }

}

@media screen and (max-width: 450px) {
    .ws-stories-box-title {
        white-space: nowrap;
        /* Prevent text from wrapping to the next line */
        overflow: hidden;
        /* Hide any content that overflows the box */
        text-overflow: ellipsis;
        /* Display an ellipsis (...) to indicate text overflow */
        /* max-width: 100%; */
        /* Set a maximum width to ensure proper ellipsis behavior */

        /* height: 44px; */
        width: 22px;
    }

    .ws-stories-box-summary {
        overflow: hidden;
        /* Hide any content that overflows the box */
        text-overflow: ellipsis;
        /* Display an ellipsis (...) to indicate text overflow */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* Limit the number of lines to 2 */
        -webkit-box-orient: vertical;
        height: 20px;
        /* width: 243.99px; */
    }
}