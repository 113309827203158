.am-privacy-page {
    padding-top: 16px;
    padding-left: 145px;
    padding-right: 146px;
    padding-bottom: 72px;
}

.am-privacy {
    color: var(--black, #000);
    text-align: center;
    font-family: Work Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 66.667% */
    letter-spacing: 0.2px;
    flex-direction: column;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 48px;
}

.am-privacy-heading {
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.am-privacy-para {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.am-privacy-para-heading {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 1200px) {
    .am-privacy-page {
        padding-top: 16px;
        padding-bottom: 24px;
        padding-left: 54px;
        padding-right: 54px;
    }
}

@media screen and (max-width: 950px) {
    .am-privacy-heading {
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 75% */
        letter-spacing: 0.2px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
    }

    .am-privacy-para-heading {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .am-privacy-para {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .am-privacy-page {
        padding-top: 0px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 200px;
    }

    .am-privacy {
        color: var(--black, #000);
        text-align: center;
        font-family: Work Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 66.667% */
        letter-spacing: 0.2px;
        flex-direction: column;
        justify-content: center;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}