/* CharacterCreate.css */

/* Style the form container */
.form-container {
    width: 400px;
    margin: 0 auto;
}

/* Style form labels */
label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.character-box {
    position: relative;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    background-color: #f9f9f9;
    /* padding-bottom: 5px; */
}

.character-close {
    position: absolute;
    top: 0px;
    right: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: black;
}

/* .character-close {
    cursor: pointer;
    margin: auto;
    font-size: 18px;
    font-weight: bold;

} */

.Existing-character-container {
    border: 2px solid #403085;
    border-radius: 8px;
    margin: 8px;
    padding: 8px;
}

.back-button {
    background-color: var(--primary-subtle, #F7F5FF);
    /* Use provided background color or fallback to #F7F5FF */
    color: #403085;
    /* Text color */
    border: 2px solid #403085;
    /* Border color matches text color */
    padding: 10px 20px;
    /* Padding for the button */
    cursor: pointer;
    /* Show pointer cursor on hover */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    /* Smooth transitions for background color, text color, and border color */
}

.back-button:hover {
    background-color: #403085;
    /* Darker background color on hover */
    color: white;
    /* Text color on hover */
}


.cool-button {
    background-color: #403085;
    /* Purple background color */
    color: white;
    /* White text color */
    border: none;
    /* Remove button border */
    padding: 10px 20px;
    /* Padding for the button */
    cursor: pointer;
    /* Show pointer cursor on hover */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    font-weight: bold;
    /* Bold text */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    /* Optional: Add box shadow for depth effect */
}

.cool-button:hover {
    background-color: #5e4ba0;
    /* Darker purple background on hover */
}

/* Style form inputs and selects */
/* input[type="text"],
select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
} */
.chinput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ch_select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Style the submit button */
.ch_submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ch_submit:hover {
    background-color: #0056b3;
}


/* button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type="submit"]:hover {

} */

/* Style the character and story ID fields */
/* #characterId,
#storyId {
    background-color: #f2f2f2;
    cursor: not-allowed;
} */

#storyId {
    background-color: #f2f2f2;
    cursor: not-allowed;
}

/* Center the "Create Character" heading */
.ch_mapping {
    font-size: 24px;
    color: #333;
    margin: 20px auto;
    /* Center horizontally and add vertical margin */
    text-align: center;
    /* Center text within the heading */
}

.ch_heading {
    font-size: 24px;
    color: #333;
    margin: 20px auto;
    /* Center horizontally and add vertical margin */
    text-align: center;
    /* Center text within the heading */
}