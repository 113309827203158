.UserDescriptionCardFirstHead {
    display: flex;
    color: var(--text-heading, #252525);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;
}

.UserDescimg {
    cursor: pointer;
    margin-left: auto;
}

.UserDescimg img {
    display: block;
    height: 24px;
    width: 24px;
}



/* Add any other styles you need for your components */