.new {
    padding-top: 73px;
}

.banner {

    background-image: url('../../Assets/Images/BG.webp');
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-size: cover;
    /* Cover the entire element */
    background-position: center center;
    /* Center the image horizontally and vertically */
    padding-left: 32px;
    padding-right: 32px;

}

.overlay {
    position: relative;
}



.background-image {
    width: 100%;
    height: auto;
    z-index: -1;
}

/* @media screen and (max-width: 6000px) { */
.ws-search-text-box {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0px;
    padding-right: 0px;
}

/* } */

@media screen and (max-width: 1200px) {

    /* .ws-search-text-box {
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 40px;
        padding-right: 40px;
    } */
    .ws-search-text-box {
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

/* @media screen and (max-width: 950px) {
    .ws-search-text-box {
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media screen and (max-width: 760px) {
    .ws-search-text-box {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 40px;
        padding-right: 40px;
    }
} */
@media screen and (max-width: 950px) {
    .ws-search-text-box {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 40px;
        padding-right: 40px;
    }
}