/* This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-23-2023
Purpose : Final Page CN Styling
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487 */

.CN-WS-Fin{
    width:100%;
    height:100%;
    text-align: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
}

.CN-WS-Fin img{
    height: 22%;
    width:22%;
}

.CN-WS-Fin div{
    width:100%;
    text-align: center;
}

#CN-WS-Fin-upper-text{
    margin-top: 5%;
    color: var(--black, #000);
    text-align: center;
    font-family: Work Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 50% */
    letter-spacing: 0.2px;
}

#CN-WS-Fin-lower-text{
    color: #2F5FE3;
    text-align: center;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 66.667% */
    letter-spacing: 0.2px;
}