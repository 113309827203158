.ws-header {
    padding: 24px;
    display: flex;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    max-width: 3000px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    /* background: linear-gradient(180deg, #FFF 40.5%, rgba(213, 200, 200, 0.00) 100%); */
    align-items: center;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.50);
    /* gap: 40px; */
    /* backdrop-filter: blur(1px); */
    /* Adjust the blur strength as needed */
    /* -webkit-backdrop-filter: blur(1px); */
    /* For Safari */
}

.header {
    height: 35px;
}

.ws-header-logo {
    height: 35px;
}

.ws-header-logo>img {
    cursor: pointer;
    height: 35px;
    width: 35px;
    display: block;
    /* Add this line to remove extra space */
}

.header2 {
    display: flex;
    padding-left: 24px;
}

.home {
    margin-right: 16px;
    cursor: pointer;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    padding: 12px 16px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.audio {
    width: max-content;
    margin-right: 16px;
    cursor: pointer;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    padding: 12px 16px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.author {
    margin-right: 16px;
    cursor: pointer;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    padding: 12px 16px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.global {
    padding-left: 15px;
    cursor: pointer;
    /* margin-top: 15px; */
    color: var(--text-heading, #000);
    /* label large */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
    display: flex;
    padding: 12px 16px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}


.home.active,
.audio.active,
.author.active

/* Add other tabs with .active as needed */
    {
    color: #403085;
    ;
    /* Your active tab color */
    /* Add other styles as needed */
}

.headerspace3 {
    flex: 1;
}

.header3 {
    display: flex;
    margin-left: auto;
}

.divwrite {
    height: 35px;
}

.logoutbtn {
    cursor: pointer;
    margin-left: 24px;
    border-radius: 20px;
    border: 1px solid #403085;
    height: 35px;
    width: 102px;
    display: flex;
    padding: 8px 12px;
    background-color: #FFF;
    color: #403085;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .logoutbtn {
    cursor: pointer;
    margin-left: 24px;
    border-radius: 8px;
    height: 35px;
    width: 102px;
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #403085;
    background-color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
    border: #403085;
    border: none;
} */

.writebtn {
    margin-top: auto;
    margin-left: 24px;
    cursor: pointer;
    border-radius: 20px;
    background: #403085;
    height: 35px;
    width: 102px;
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
    border: none;
}

.divlog {
    padding-left: 24px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}

.divlog img {
    display: block;
    height: 30px;
    width: 30px;
    border-radius: 100px;
}

@media screen and (max-width: 7000px) {
    .web {
        display: block;
    }

    .mobile {
        display: none;
    }

    .ws-mobile-header {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    .web {
        display: none;
    }

    .mobile {
        display: block;
    }

    .ws-header {
        display: none;
    }

    .ws-mobile-header {
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.50); */
        display: flex;
        height: 59px;
        /* width: 100%; */
    }

    .headerspacemob1 {
        flex: 0.04;
    }

    .headerspacemob2 {
        flex: auto;
        /* flex: 0.4; */
    }

    .ws-mob-logo {
        margin-left: auto;
    }

    .mob-search-input {
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        /* Initially hidden */
    }

    .mob-search-input.active {
        visibility: visible;
        /* Show the input when active class is added */
    }

    .mob-search-input.active input {
        margin-left: 4px;
        margin-right: 4px;
        border: 1px solid #333;
        /* Example border color */
        padding: 8px;
        border-radius: 4px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
    }

    .mob-search-input input::placeholder {
        color: #888;
        /* Example placeholder color */
    }

}