.am-exception {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    text-align: center;
    /* If you want to center text within each div */

}

.am-exception img {
    height: 200px;
    width: 200px;
}

.am-exception-header {
    padding-top: 8px;
    font-family: 'Work Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 18.77px;
    color: #403085;

}

.am-exception-para {
    padding-top: 8px;
    font-family: 'Work Sans';
    font-weight: 400;
    line-height: 14.08px;
    text-align: justify;
}

.am-exception-back {
    padding-top: 12px;
    gap: 8px;
    display: flex;
    color: #403085;
    font-family: 'Work Sans';
    font-weight: 500;
    line-height: 16.42px;
    font-size: 14px;
}


.am-exception-back img {
    display: block;
    /* display: inline-block; */
    height: 16px;
    width: 16px;
}