.ws-search-whitebox-new {
    display: flex;
    margin-top: 4px;
    width: 100%;
    padding: 2px 0px 0px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 46px;
    border: 1px solid #D9D9D9;
    background: var(--white, #FFF);
    height: -webkit-fill-available;
}

.ws-new-search-padding {
    padding-left: 250px;
    padding-right: 36px;
}

.ws-search-whitebox-new>img {
    height: 18px;
    width: 18px;
}

.ws-search-input:focus {
    border: none;
}

.ws-search-box-new {
    width: 100%;
    height: 42px;
}

.ws-search-input-new {
    outline: none;
    display: flex;
    width: 100%;
    padding: 3.7px 16px 3.7px 4px;
    border-radius: 4px;
    border: none;
    background: var(--white, #FFF);
    color: var(--text-secondary, #848484);
    font-style: italic;
    font-family: 'Work Sans';
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    /* Adjust the value to make it rounder */
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.2px;
}

@media screen and (max-width: 1300px) {
    .ws-new-search-padding {
        padding-left: 50px;
        padding-right: 36px;
    }
}

@media screen and (max-width: 1200px) {
    .ws-new-search-padding {
        padding-left: 0px;
        padding-right: 36px;
    }
}