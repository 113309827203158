#FavoriteAuthorSearchAreaSearchBar {
    padding: 24px;
}

.FavoriteAuthorSearchAreaSearchResults {
    font-weight: 500;
    font-size: 40px;
}

.FavoriteAuthorSearchAreaContent {
    padding: 24px 110px 16px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Four cards per row */
    grid-gap: 24px;
    /* Adjust the gap between cards */
}

.FA-NoAuthor {
    font-family: 'work sans';
    font-size: 20px;
    color: #857a7a;
}