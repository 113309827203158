.AuthorAudioStoryCardMainSection {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AuthorAudioStoryCardImgSection {
    position: relative;
}

.AuthorAudioStoryCardImgSection img {
    width: 223px;
    height: 205px;
    border-radius: 8px;
    object-fit: cover;
}

.AuthorAudioStoryCardImgSectionBg {
    position: absolute;
    width: 223px;
    height: 205px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), lightgray 50% / cover no-repeat;
    top: 0;
    opacity: 50%;
}

#AuthorAudioStoryCardImgSectionPlay {
    position: absolute;
    top: 64px;
    left: 75px;
    width: 72.286px;
    height: 77.662px;
}

#AuthorAudioStoryCardImgSectionPlay img {
    width: 72.286px;
    height: 77.662px;
}

.AuthorAudioStoryCardInfoSection {
    display: flex;
    flex-direction: column;
}

.AuthorAudioStoryCardInfoSectionStoryTitle {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.AuthorAudioStoryCardInfoSectionAuthor {
    margin-bottom: 24px;
}

.AuthorAudioStoryCardInfoSectionWriterName {
    color: var(--text-primary, #474747);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 88.889% */
    letter-spacing: 0.6px;
    margin-right: 16px;
}

.AuthorAudioStoryCardInfoSectionSpeakerName {
    color: var(--text-primary, #474747);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 88.889% */
    letter-spacing: 0.6px;
}

.AuthorAudioStoryCardInfoSectionAuthorPostDate {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 2px;
}

.AuthorAudioStoryCardInfoSectionStoryBrief {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.AuthorAudioStoryCardInfoSectionData {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.AuthorAudioStoryCardInfoSectionDataViews,
.AuthorAudioStoryCardInfoSectionDataRating {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.AuthorAudioStoryCardInfoSectionDataFirst {
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorAudioStoryCardInfoSectionDataSecond {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 950px) {
    .AuthorAudioStoryCardImgSection img {
        width: 96px;
        height: 100px;
        border-radius: 8px;
        object-fit: cover;
    }

    .AuthorAudioStoryCardImgSectionBg {
        width: 96px;
        height: 100px;
    }

    #AuthorAudioStoryCardImgSectionPlay {
        position: absolute;
        top: 30px;
        left: 25px;
        width: 40px;
        height: 40px;
    }

    #AuthorAudioStoryCardImgSectionPlay img {
        width: 40px;
        height: 40px;
    }

    .AuthorAudioStoryCardInfoSectionStoryTitle {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .AuthorAudioStoryCardInfoSectionStoryBrief {
        width: auto;
        margin-bottom: 2px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 27px;
        /* /* width: 243.99px; */
    }

    .AuthorAudioStoryCardInfoSectionAuthor {
        margin-bottom: 0px;
    }

    .AuthorAudioStoryCardInfoSectionWriterName {
        font-size: 12px;
    }

    .AuthorAudioStoryCardInfoSectionSpeakerName {
        font-size: 12px;
    }

    .AuthorAudioStoryCardInfoSectionAuthorPostDate {
        font-size: 12px;
    }

    .AuthorAudioStoryCardInfoSectionDataFirst {
        font-size: 12px;
    }

    .AuthorAudioStoryCardInfoSectionDataSecond {
        font-size: 12px;
    }
}