.am-cookie-page {
    padding-top: 16px;
    padding-left: 145px;
    padding-right: 146px;
    padding-bottom: 72px;
    min-height: 80vh;
}

.am-cookie {
    color: var(--black, #000);
    text-align: center;
    font-family: Work Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 66.667% */
    letter-spacing: 0.2px;
    flex-direction: column;
    justify-content: center;
    padding-top: 16px;
}

.am-cookie-para {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 1200px) {
    .am-cookie-page {
        padding-top: 16px;
        padding-bottom: 24px;
        padding-left: 54px;
        padding-right: 54px;
    }
}

@media screen and (max-width: 950px) {
    .am-cookie-page {
        padding-top: 0px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .am-cookie {
        color: var(--black, #000);
        text-align: center;
        font-family: Work Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 66.667% */
        letter-spacing: 0.2px;
        flex-direction: column;
        justify-content: center;
        padding-top: 0px;
    }

    .am-cookie-para {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}