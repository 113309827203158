/* This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-8-2023
Purpose : Writing Part Styling in Contribution Network 
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487 */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.CN-navBar {
    height: 56px;
    display: flex;
    flex-direction: row;
    padding: 0px 15px;
    margin-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 75px;
    border-bottom: solid 1px rgb(211, 211, 211);
}

#wbs-icon {
    height: 47px;
    width: 47px;
    cursor: pointer;
}

.undo-buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: auto;
    margin-left: 40px;
}

.undo-buttons img {
    height: 26px;
    width: 26px;
    cursor: pointer;
}

#anticlockwise {
    transform: scaleX(-1);
}

.userMenu {
    margin: auto 0;
    gap: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

#userIcon {
    height: 36px;
    width: 36px;
}

#userDropDownArrow {
    height: 16px;
    width: 16px;
    margin: auto 0;
}

.second-area-navbar {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.second-area-navbar button {
    margin: auto;
}

.cn-Draftbtn {
    display: flex;
    gap: 16px;
}

#undo {
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: white;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #000;
    gap: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

#undo img {
    display: block;
    height: 24px;
    width: 24px;
}

#redo {
    cursor: pointer;
    padding: 0;
    margin-right: 8px;
    border: none;
    background-color: white;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #000;
    gap: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

#redo img {
    display: block;
    height: 24px;
    width: 24px;
}

#saveDraftBtn {
    padding: 8px 12px;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    background-color: white;
    border: none;
    cursor: pointer;
}

#saveDraftBtn img {
    display: block;
    height: 24px;
    width: 24px;
}

#submitBtn {
    border-radius: 8px;
    /* padding: 8px 12px 8px 12px; */
    padding: 8px 32.925px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background: #403085;
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    cursor: pointer;
}


#notification-bell {
    height: 26px;
    width: 26px;
    margin: auto;
    cursor: pointer;
}

.CustomBar {
    display: flex;
    flex-direction: row;
    padding-bottom: 24px;
}

.CustomBar div {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.6px;
    margin: auto 0;
}

.CustomBar button {
    font-family: 'Work Sans', sans-serif;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    cursor: pointer;
    padding: 5px;
    font-size: 12px;
    border-radius: 8px;
    /* outline: 0.5px solid rgba(0, 0, 0, 0.50); */

    border: 0.5px solid var(--Border, #AFB1B6);
}

.CustomBar button:hover {
    background-color: rgb(247, 247, 247);
}

#btn-add-people {
    padding: 11.2px 14.95px;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
    margin-left: 16px;
    gap: 8px;
}

/* #side-dropdown {
    transform: rotate(-90deg);
} */

/* 
.btn-select-lang-box{
    display: flex;
    flex-direction: column;
} */
#btn-select-lang img {
    transform: rotate(90deg);
}

#btn-select-lang {
    position: absolute;
    right: 24px;
    padding: 11.2px 15.075px;
    gap: 8px;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 88.889% */
    letter-spacing: 0.6px;
}

#select-lang-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 24px;
    background-color: aliceblue;
    width: 207.250px;
    margin-top: 42px;
    padding: 8px 15.075px;
    border-radius: 8px;
    border: 0.5px solid #AFB1B6;
    background: var(--white, #FFF);
}

#select-lang-dropdown div {
    padding: 4px 16px;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.2px;
    cursor: pointer;
}

/* .select-lang-dropdown{
    display: flex;
    flex-direction: column;
} */

.CustomBar img {
    height: 16px;
    width: 16px;
    margin: auto;
    object-fit: contain;
}

.WritingPart {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    border-radius: 16px;
    background: #FBFAFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.WritingPart textarea {
    border: none;
    /* margin: 8px; */
    resize: none;
    font-family: 'Work Sans', sans-serif;
}

textarea:focus {
    outline: none;
    outline-width: 0;
}

#cn-title {
    width: 100%;
    padding: 0px;
    font-size: 18px;
}

#cn-beginning {
    width: 100%;
    font-size: 18px;
    /* font-style: italic; */
}

#cn-story {
    width: 100%;
    height: 500px;
    font-size: 18px;
}

#cn-message {
    width: 100%;
    font-size: 18px;
}


#popup-overlay {
    /* display: none; */
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.81);
    z-index: 10;
}