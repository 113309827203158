.cn-author-banner {
    padding: 24px;
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
}

.author-details {
    cursor: pointer;
    /* Style author details */
}

.author-banner {
    display: flex;
    height: 342px;
    padding: 40px;
    align-items: center;
    justify-content: center;
    /* Center horizontally */
    border-radius: 16px;
    background: #403085;
}

.cn-author-search {
    display: flex;
    flex: 0.5;
}

.cn-author-trend-box {
    flex: 0.9;
}

.cn-author-trending {
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.6px;
}

.cn-author-trend-text {
    padding: 16px 0px;
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.cn-author-searchbar {
    display: flex;
    padding: 12px 12px 12px 16px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px var(--Border, #AFB1B6);
    /* Adjust the values to create a shadow effect */
    background: #FFF;
}

.cn-author-searchbar img {
    padding-right: 6px;
    display: block;
    width: 24px;
    height: 24px;

}

.cn-search-input::placeholder {
    flex: 1 0 0;
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.cn-search-input:focus {
    border: none;
}

.cn-search-input {
    background: #FFF;
    outline: none;
    display: flex;
    width: 100%;
    /* width: 742px; */
    /* padding: 12px 12px 12px 4px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: none;
    background: #FFF;
    font-size: 16px;
}

.cn-author-trend-profile {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
}

.cn-empty-space {
    flex: 0.1;
}

.cn-author-profile-box {
    align-self: flex-end;

}

.cn-author-profile-boxone {
    cursor: pointer;
    display: flex;
}

.cn-author-profile-boxtwo {
    margin-top: -18px;
    cursor: pointer;
    display: flex;
    padding-left: 69px;
}

.cn-author-profile-boxthree {
    margin-top: -18px;
    cursor: pointer;
    display: flex;
}

.cn-author-profile-boxone img {
    padding: 0px 12px;
    height: 130px;
    width: 114px;
}

.cn-author-profile-boxtwo img {
    padding: 0px 12px;
    height: 130px;
    width: 114px;
}

.cn-author-profile-boxthree img {
    padding: 0px 12px;
    height: 130px;
    width: 114px;
}

@media screen and (max-width: 950px) {
    .author-banner {
        display: block;
        height: 264px;
        padding: 16px 24px;
        align-items: center;
        justify-content: center;
        /* Center horizontally */
        border-radius: 16px;
        background: #403085;
    }

    .cn-author-trending {
        padding-top: 8px;
        align-self: stretch;
        color: var(--white, #FFF);
        text-align: center;
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .cn-author-trend-text {
        padding-top: 8px;
        color: var(--white, #FFF);
        text-align: center;
        font-family: "Work Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.6px;
    }

    .cn-author-trend-profile {
        flex: 1;
        display: block;
        justify-content: flex-end;
    }

    .cn-author-imageboxone {
        /* display: flex; */
        justify-content: center;
        width: 100%;
        /* Ensure the parent container takes up the full width */
    }

    .cn-author-profile-boxone {
        display: flex;
        justify-content: center;
        /* gap: 10px; */
        /* Adjust the gap between images as needed */
    }

    .cn-author-profile-boxone {
        cursor: pointer;
        display: flex;
    }

    .cn-author-profile-boxtwo {
        margin-top: -10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding-left: 60px;
    }

    .cn-author-profile-boxthree {
        margin-top: -10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .cn-author-profile-boxone {
        margin-left: auto;
        /* margin-right: auto; */
    }

    .cn-author-profile-boxone img {
        padding: 0px 6px;
        height: 63px;
        width: 51px;
    }

    .cn-author-profile-boxtwo img {
        padding: 0px 6px;
        height: 63px;
        width: 51px;
    }

    .cn-author-profile-boxthree img {
        padding: 0px 6px;
        height: 63px;
        width: 51px;
    }
}