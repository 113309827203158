.AuthorProfileLeftCard {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 200px;
    text-align: center;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.AuthorProfileLeftCardFirst img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background: lightgray 50% / cover no-repeat, #E8E3FF;
}

.AuthorProfileLeftCardSecond {
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.AuthorProfileLeftCardSecondName {
    color: var(--text-heading, #252525);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.AuthorProfileLeftCardSecondTitle {
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
}

.AuthorProfileLeftCardThird {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
}

.AuthorProfileLeftCardFourth {
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.AuthorProfileLeftCardFourthUpper button {
    padding: 8px 12px;
    border-radius: 8px;
    background: #403085;
    border: none;
    color: var(--white, var(--white, #FFF));
    width: 144px;
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
    cursor: pointer;
}

.AuthorProfileLeftCardFourthUpper button div {
    display: flex;
    flex-direction: row;
    height: 16px;
    justify-content: center;
}

.AuthorProfileLeftCardFourthUpper button img {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.AuthorProfileLeftCardFourthLower button {
    border-radius: 8px;
    background: var(--primary-subtle, #F7F5FF);
    padding: 8px 12px;
    border: none;
    color: #403085;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
    cursor: pointer;
}

.AuthorProfileLeftCardFourthLower button div {
    display: flex;
    flex-direction: row;
    height: 16px;
}

.AuthorProfileLeftCardFourthLower button img {
    margin-right: 8px;
}

.AuthorProfileLeftCardFifth {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
}

.AuthorProfileLeftCardFifth span {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.AuthorProfileLeftCardFifth div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
}

.AuthorProfileLeftCardFifth div img {
    margin-right: 16px;
    height: 16px;
    width: 16px;
}

.AuthorProfileLeftCardFifth div #AuthorProfileLeftCardFifthFollowingImg {
    margin-right: 16px;
    height: 14px;
    width: 14px;
}

.AuthorProfileLeftCardFifthTag {
    margin-right: 4px;
    color: var(--text-primary, #474747);
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 950px) {
    .AuthorProfileFavFollow {
        display: flex;
        justify-content: center;
        /* Center the items horizontally */
        align-items: center;
        /* Center the items vertically */
        gap: 20px;
        /* Adjust spacing between buttons if needed */
        margin-top: 16px;
    }

}