.cn-new-discover-page {
    padding: 24px 110px 16px 0px;
}

.cn-discover-author {
    min-height: 100vh;
    padding: 24px 24px 24px 24px;
}

.cn-new-community-page {
    padding: 24px 110px 0px 0px;
}

.cn-new-choose-by-you {
    padding: 40px 110px 16px 0px;
}

/* Style for the author card container */
.author-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Four cards per row */
    grid-gap: 24px;
    /* Adjust the gap between cards */
}

/* Style for the author card container */
.author-favorite-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Four cards per row */
    grid-gap: 24px;
    /* Adjust the gap between cards */
}

.cn-follow-fav {
    display: flex;
    gap: 20px;
}

.frame-author-img {
    padding-bottom: 24px;
}

.frame-author-design {
    padding-bottom: 24px;
    /* text-align: center; */
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.frame-author-img img {
    display: block;
    height: 215px;
    width: 215px;
    border-radius: 100px;
}

.discover-author-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Four cards per row */
    grid-gap: 24px;
    /* Adjust the gap between cards */
}

.cn-image-text-container {

    position: relative;
    width: 100%;
    height: 515px;
    /* Set a fixed height for the image-text container */
    overflow: hidden;
    /* Clip any content that exceeds the height */
    display: flex;
    align-items: flex-start;
}

.cn-text-container {
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Make the text container cover the full height of its parent */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    text-align: center;
}

.cn-discover {
    padding-bottom: 16px;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Work Sans;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    /* 75% */
    letter-spacing: 0.6px;
}

.cn-bg-discover-author {
    margin-top: 98px;
}

.cn-discover-search {
    padding-bottom: 54px;
    margin-bottom: -400px
}

.cn-explore {
    padding-bottom: 16px;
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.cn-discover-author-bg {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 100%;
    /* Ensure the image-overlay takes the full height */
}

.cn-discover-author-bg img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    /* Set the image to cover the full height of its container */
    object-fit: cover;
}

@media screen and (max-width: 1200px) {

    .author-card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* Four cards per row */
        grid-gap: 24px;
        /* Adjust the gap between cards */
    }

    .author-favorite-card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* Four cards per row */
        grid-gap: 24px;
        /* Adjust the gap between cards */
    }
}

@media screen and (max-width: 950px) {
    .cn-new-discover-page {
        padding: 24px 24px 16px 0px;
    }

    .discover-author-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Four cards per row */
        grid-gap: 8px;
        /* Adjust the gap between cards */
    }

    .cn-discover {
        font-size: 18px;
    }

    .cn-discover-author {
        min-height: 100vh;
        padding: 24px 24px 100px 24px;
    }

    .cn-new-community-page {
        padding: 24px 0px 0px 0px;
    }

    .cn-image-text-container {

        height: 199px;
    }

    .author-card-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        /* Four cards per row */
        grid-gap: 24px;
        /* Adjust the gap between cards */
    }

    .author-favorite-card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /* Four cards per row */
        grid-gap: 24px;
        /* Adjust the gap between cards */
    }

    .author-favorite-card-container {
        padding-bottom: 4px;
        /* display: flex; */
        /* overflow-x: auto;
        scroll-behavior: smooth; */
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .author-favorite-card-container::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    .author-favorite-card-container::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    .cn-explore {
        font-size: 12px;
    }
}

@media screen and (max-width: 610px) {
    .author-favorite-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}