.AuthorPublicationCardMainSection {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AuthorPublicationCardImgSection img {
    width: 223px;
    height: 205px;
    border-radius: 8px;
    object-fit: cover;
}

.AuthorPublicationCardInfoSection {
    display: flex;
    flex-direction: column;
}

.AuthorPublicationCardInfoSectionStoryTitle {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.AuthorPublicationCardInfoSectionAuthor {
    margin-bottom: 24px;
}

.AuthorPublicationCardInfoSectionAuthorName {
    color: var(--text-primary, #474747);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorPublicationCardInfoSectionAuthorPostDate {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorPublicationCardInfoSectionStoryBrief {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.AuthorPublicationCardInfoSectionData {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.AuthorPublicationCardInfoSectionDataViews,
.AuthorPublicationCardInfoSectionDataRating {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.AuthorPublicationCardInfoSectionDataFirst {
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorPublicationCardInfoSectionDataSecond {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (max-width: 950px) {
    .AuthorPublicationCardMainSection {
        gap: 8px;
    }

    /* styles.css */
    .authorprofiletab {
        display: flex;
        margin-top: 16px;
        margin-left: 16px;
    }

    .button-style {
        border-radius: 8px 8px 0px 0px;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .button-style.selected {
        background: #F7F5FF;
    }

    .button-style.not-selected {
        background: #FFFFFF;
    }

    .button-style:hover {
        background: #e0dcff;
    }

    .button-style img {
        margin-right: 5px;
    }

    .text-selected {
        color: #403085;
        font-family: "Work Sans", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.6px;
    }

    .text-not-selected {
        color: #000;
        font-family: "Work Sans", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.6px;
    }



    .AuthorPublicationCardInfoSectionStoryTitle {
        font-size: 16px;
        margin-bottom: 0px;
    }

    .AuthorPublicationCardInfoSectionStoryBrief {
        width: 80%;
        margin-bottom: 4px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 27px;
        /* /* width: 243.99px; */
    }

    .AuthorPublicationCardInfoSectionAuthor {
        margin-bottom: 4px;
    }

    .AuthorPublicationCardInfoSectionAuthorName {
        font-size: 12px;
    }

    .AuthorPublicationCardInfoSectionDataFirst {
        font-size: 12px;
    }

    .AuthorPublicationCardInfoSectionDataSecond {
        font-size: 12px;
    }

    .AuthorPublicationCardInfoSectionAuthorPostDate {
        font-size: 12px;
    }

    .AuthorPublicationCardImgSection img {
        width: 96px;
        height: 100px;
    }

    /* .AuthorAudioStoryCardImgSectionBg {
    width: 96px;
    height: 100px;
} */

}