.cn-community-page {
    padding: 0px 0px 24px 24px;
    min-height: 100vh;
}

.cn-community-author-favorite {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--primary-subtle, #F7F5FF);
}

.cn-follow-author-bio {
    padding-bottom: 16px;
    color: var(--text-primary, #474747);
    text-align: justify;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

@media screen and (max-width: 950px) {
    .cn-community-author-favorite {
        margin-top: 8px;
    }

    .cn-follow-author-bio {
        font-size: 9px;
    }
}