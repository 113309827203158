.AuthorCardMainSection {
    margin: 24px 0;
    margin-left: 130px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AuthorCardFirstSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.AuthorCardSecondSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media screen and (max-width: 950px) {
    .AuthorProfilePicture {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 96px;
        /* Adjust height as needed */
        width: 96px;
        /* Adjust width as needed */
        margin: 0 auto;
        /* Center the container itself if needed */

    }

    .AuthorProfilePicture img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 96px;
        /* Ensure the image fits within the container without distortion */
    }

    .AuthorProfileName {
        margin-left: auto;
        align-self: stretch;
        color: #000;

        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .AuthorProfileDesign {
        align-self: stretch;
        color: var(--text-primary, #474747);
        text-align: center;
        font-family: "Work Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 0.2px;
    }

    .AuthorDescriptionBio {
        align-self: stretch;
        color: var(--text-primary, #474747);
        text-align: center;
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
    }

    .AuthorDot {
        font-size: xx-large;
        margin-top: -20px;
        display: flex;
        align-items: center;
    }


    .AuthorProfileFollow {
        color: var(--text-heading, #252525);
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        /* Adds space between the follower and following sections */
    }

    .AuthorProfileFollow>div {
        display: flex;
        align-items: center;
        gap: 5px;
        /* Adds space between the count and the text */
    }

    .AuthorDot {
        display: flex;
        align-items: center;
    }

}