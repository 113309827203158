.as-audio-play {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.as-audio-pause img {
    width: 72.286px;
    height: 77.662px;
}

.as-audio-pause {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.as-audio-play img {
    width: 72.286px;
    height: 77.662px;
}

.as-audio-playstories {
    z-index: 1;
    /* set a higher z-index */
    position: fixed;
    background: #403085;
    width: 100%;
    bottom: 0px;
}

.as-expand {

    background: #F9F7FF;
    width: 100%;
    height: 100%;
    bottom: 0px;
}

.as-player-search {
    display: block;
}

.as-player-search-expand {
    display: none;
}

.as-player-box-hide {
    display: none;
}

.as-player-box-expand-none {
    display: none;
}

.as-player-box-expand {
    display: flex;
}

.as-audio-detail-view {
    flex: 0.7;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 140px;
}



.as-audio-backbtn {
    display: flex;
    cursor: pointer;
}

.as-audio-backbtn img {
    height: 32px;
    width: 32px;
}

.as-audio-now-playing {
    padding-left: 24px;
    color: #000;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.as-audio-detailview {
    width: 551px;
    margin-left: auto;
    margin-right: auto;
}

.as-detailview-thumbnailimg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.as-detailview-thumbnailimg img {
    border-radius: 16px;
    width: 551px;
    height: 489px;
}

.as-detailview-heart {
    margin-right: auto;
}


.as-detailview-heart-icon img {
    width: 33.594px;
    height: 32px;
}

.as-detailview-bookmark-share {
    display: flex;
    margin-left: auto;
}

.as-audio-TitleAuthorNarrator {
    margin-top: auto;
    text-align: center;
}

.as-audio-storyTitle {
    padding-bottom: 16px;
    margin-top: auto;
    text-align: center;
    color: #4E4E4E;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.as-detailview-storyTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 16px;

}

.as-detailview-authorNarrator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-primary, #474747);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.as-detailview-playrate {
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.as-detail-play {
    padding-right: 16px;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-detail-playno {
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-detail-rate {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-detail-rateno {
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-detailview-ads {
    flex: 0.3;
    padding-top: 24px;
    padding-bottom: 40px;
}



.as-player-box {
    display: flex;
    padding: 24px;
}

.as-player-box {
    display: flex;
    align-items: center;
}

.as-playstories-img {
    align-self: flex-start;
}

.as-player-authorName {
    flex: 0.2;
    text-align: left;
    padding-left: 16px;
    /* Adjust margin as needed */
}

.as-player-rplaypausef {
    flex: 0.5;
    justify-content: center;
    align-items: center;
    /* Align items vertically in the center */

}

.ws-stories-expand {
    display: none;
}

.as-audio-pagination {
    display: block;
}

.as-audio-pagination-expand {
    display: none;
}

.as-expandbtn {
    cursor: pointer;
    position: relative;
    flex: 0.3;
    left: 26%;
}

.as-expandbtn img {
    margin-right: auto;
    margin-left: auto;
    height: 24px;
    width: 24px;
}

.as-player-playpausebtn {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
}

.as-audio-playpausebtn {
    display: flex;
    justify-content: center;
    padding-bottom: 95px;
}

.seekbar {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.seekbar-expand {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 8px;
}

.seekinginput-expand {
    width: 100%;
}

.seekinginput-expand input[type="range"] {
    width: 100%;
}

.slider-expand {
    width: 100%;
    height: 8px;
    accent-color: #403085;

    border-radius: 0px;
}

.slider-expand::-webkit-slider-thumb {

    position: relative;
    background: red;

    ::after {
        content: 'qwwwwwww';
        position: absolute;
        left: 0px;
        top: 0px;
        height: 20px;
        width: 20px;
        border: 1px solid red;
        z-index: 1;
    }

}

.as-player-storyTitle {
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.as-audio-timeShow {
    display: flex;
    padding-bottom: 40px;
}

.as-player-currenttime {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px 8px;
}

.as-player-currenttime {
    margin: 0px;
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.as-player-currenttimeEX {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px 8px;
}

.as-player-currenttimeEX {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-player-maxtimeEX {
    margin-left: auto;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-player-timerewindEX {
    margin-top: auto;
    margin-bottom: auto;
}

.as-player-timerewindEX img {
    display: block;
    cursor: pointer;
    margin-right: 48px;
    height: 34px;
    width: 34px;
}

.as-player-timerewind {
    margin-top: auto;
    margin-bottom: auto;
}

.as-player-timerewind img {
    display: block;
    cursor: pointer;
    margin-right: 54px;
    height: 32px;
    width: 32px;
}

.as-player-playpause img {
    display: block;
    margin-top: auto;
    cursor: pointer;
    height: 64px;
    width: 64px;
}

.as-player-timeforward {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.as-player-timeforward img {
    width: 100px;
    cursor: pointer;
    margin-left: 54px;
    height: 32px;
    width: 32px;
}

.ws-shareEX>img {
    width: 32px;
    height: 32px;
}

.ws-bookmarkEX>img {
    padding-right: 24px;
    width: 25.798px;
    height: 32px;
}

.as-player-timeforwardEX {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.as-player-timeforwardEX img {
    width: 100px;
    cursor: pointer;
    margin-left: 48px;
    height: 34px;
    width: 34px;
}

.as-player-time {
    display: flex;
    position: relative;
    text-align: right;
    margin-right: 10px;

}

.as-player-img-hsb {
    flex: 0.1;
    display: flex;
}

.as-player-heart>img {
    cursor: pointer;
    padding-right: 24px;
    width: 29.395px;
    height: 28px;
}

.as-player-share>img {
    cursor: pointer;
    padding-right: 24px;
    width: 28px;
    height: 28px;
}

.as-player-bookmark>img {
    cursor: pointer;
    padding-right: 16px;
    width: 22.573px;
    height: 28px;
}

.as-audio-rating {
    display: flex;
    justify-content: center;
    align-items: center;

}

.as-detail-rating {
    padding-right: 16px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-audio-detail-ratestar {
    display: block;
}

.as-audio-detail-ratestar img {
    margin-top: 5px;
    cursor: pointer;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.as-playstories-img img {
    display: block;
    height: 94px;
    width: 94px;
    flex-shrink: 0;
    border-radius: 8px;

}

.seekinginput {
    height: 7px;
    width: 100%;
}

.seekinginput input[type="range"] {
    width: 100%;
}

.slider {
    margin: 0px;
    width: 100%;
    height: 6px;
    accent-color: #000;
    border-radius: 0px;
}

.slider::-webkit-slider-thumb {

    position: relative;
    background: red;

    ::after {
        content: 'qwwwwwww';
        position: absolute;
        left: 0px;
        top: 0px;
        /* height: 20px; */
        width: 20px;
        border: 1px solid red;
        z-index: 1;
    }

}

.as-story-rate {
    padding-right: 8px;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.as-story-ratetext {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-stories-box-author img {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

.as-audio-box-narrator {
    margin-left: 16px;
    color: var(--text-primary, #474747);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.as-audio-box-narrator img {
    margin-top: 2px;
    height: 16px;
    width: 16.25px;
    margin-right: 4px;
}

.as-audio-img {
    position: relative;
    flex: 1;
    overflow: hidden;
    height: 100%;
    /* Ensure the image-overlay takes the full height */
}

.as-audio-img img {
    display: block;
    border-radius: 8px;
    height: 231px;
    width: 260px;
}

.as-stories-text {
    flex: 1;
}

.as-audio-btn {
    position: relative;
    border-radius: 8px;
    height: 231px;
    width: 260px;
    /* Set a fixed height for the image-text container */
    overflow: hidden;
    /* Clip any content that exceeds the height */
    display: flex;
    align-items: flex-start;
}

.as-audio-player-playbtn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Make the text container cover the full height of its parent */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
}

@media screen and (max-width: 950px) {
    .as-player-currenttime {
        padding: 0px 0px;


        color: var(--white, #FFF);
        font-family: Work Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

    }

    .timelap {
        margin-left: 8px;
        display: flex;
    }

    .seekinginputmob-none {
        display: none;
    }

    .seekinginputmob {
        width: 100%;
        margin-top: -14px;
    }

    .seekinginputmob input[type="range"] {
        margin-left: -2px;
        width: 100%;
    }

    .as-audio-detail-view {
        flex: 1;
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        padding-bottom: 140px;
    }

    .as-playstories-img {
        align-self: flex-start;
    }

    .as-player-box {
        display: flex;
        padding: 16px;
    }

    .as-playstories-img img {
        height: 32px;
        width: 32px;
        flex-shrink: 0;
        border-radius: 8px;
    }

    .as-player-storyTitle {
        font-size: 10px;
    }

    .as-audio-playstories {
        z-index: 1;
        /* set a higher z-index */
        position: fixed;
        background: #403085;
        width: 100%;
        bottom: 9%;
    }

    .as-detailview-heart-icon img {
        width: 14px;
        height: 14px;
    }

    .as-audio-storyTitle {
        font-size: 16px;
    }

    .as-detailview-authorNarrator {
        font-size: 10px;
    }

    .as-audio-detailview {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .as-detailview-thumbnailimg {
        padding-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .as-detailview-thumbnailimg img {
        border-radius: 16px;
        width: 300px;
        /* height: 489px; */
        height: 317.19px;
    }

    .as-player-timerewind img {
        cursor: pointer;
        margin-right: 32px;
        height: 24px;
        width: 24px;
    }

    .as-player-rplaypausef {
        flex: 0.6;
        justify-content: center;
        align-items: center;
        /* Align items vertically in the center */

    }

    .as-expandbtn {
        cursor: pointer;
        position: relative;
        flex: 0.3;
        left: 18%;
    }

    .as-expandbtn img {
        margin-right: auto;
        margin-left: auto;
        height: 16px;
        width: 16px;
    }

    .as-player-timeforward img {
        width: 100px;
        cursor: pointer;
        margin-left: 32px;
        height: 24px;
        width: 24px;
    }

    .as-player-playpause img {
        margin-top: auto;
        cursor: pointer;
        height: 48px;
        width: 48px;
    }

    .as-detailview-ads {
        display: none;
    }

    .as-expand {
        background: #F9F7FF;
        width: 100%;
        height: 100%;
        bottom: 0px;
    }

    .as-audio-playstories {
        display: block;
    }

    .as-audio-img img {
        border-radius: 8px;
        height: 107px;
        width: 110px;
    }

    .as-audio-btn {
        position: relative;
        border-radius: 8px;
        height: 107px;
        width: 110px;
        /* Set a fixed height for the image-text container */
        overflow: hidden;
        /* Clip any content that exceeds the height */
        display: flex;
        align-items: flex-start;
    }

    .as-audio-play img {
        width: 50.262px;
        height: 54px;
    }

    .as-audio-pause img {
        width: 50.262px;
        height: 54px;
    }

    /* detailviewmobile */
    .as-player-box-expand {
        min-height: 100vh;
        display: flex;
        padding: 24px;
    }

    .as-mob-audio-detail-view {
        width: 100%;
    }

    .as-mob-navbar {
        display: flex;
        width: 100%;
    }

    .as-mob-nowplaying {
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        flex: 0.5;
    }

    .as-mob-arrowdown {
        margin-top: auto;
        margin-bottom: auto;
        flex: 0.25;
    }

    .as-mob-arrowdown img {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .as-mob-bookmark {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: flex-end;
        flex: 0.25;
    }

    .as-mob-bookmark img {
        width: 12.899px;
        height: 16px;
        flex-shrink: 0;
    }

    .as-mob-category-img {
        padding-top: 24px;
    }

    .as-mob-category-img img {
        border-radius: 8px;
        height: 317.19px;
        width: 100%;
    }

    .as-detailview-storyTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 16px;

    }

    .as-detailview-playrate {
        padding-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .seekbar-expand {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        padding-top: 0px;
        padding-bottom: 8px;
    }

    .as-audio-expand-time {
        display: flex;
        padding-bottom: 54px;
    }

    .as-audio-currenttime {
        margin-right: auto;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .as-audio-maxtime {
        margin-left: auto;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .as-audio-playpausebtn {
        display: flex;
        justify-content: center;
        padding-bottom: 64px;
    }
}