.mscrollr {
    margin-right: -30px;
    padding-left: 10px;
    height: 74vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    border-radius: 20px;
}

.mscrollerpolicy {
    margin-right: -20px;
    padding-left: 10px;
    height: 68vh;
    overflow-y: scroll;
    scrollbar-width: thin;
}