.pop-up-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.88);
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    /* Ensure it's above other content */
}

.speech-bubble {
    padding: 0px 16px;
    left: 60%;
    position: relative;
    background: var(--white, #FFF);
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-1px 0px 4px rgba(0, 0, 0, 0.25));
    /* border-radius: .4em; */
    border-radius: 8px;
}

.speech-bubble-container {
    padding-top: 24px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Adjust the margin as needed */
}

.speech-bubble:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-bottom-color: var(--white, #FFF);
    border-top: 0;
    margin-left: -20px;
    margin-top: -8px;
}

.ws-rating-signup {
    cursor: pointer;
    color: #403085;

    text-align: justify;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.box-content {
    padding-top: 12px;
    padding-bottom: 12px;
    height: 19px;
    display: block;
    color: #000;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #333;
}

/* .arrow-box {

    position: absolute;
    background: var(--white, #FFF);
    padding: 0px 10px 0px 10px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
}

.ws-rating-signup {
    cursor: pointer;
    color: #403085;

    text-align: justify;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--white, #FFF);
    border: (0px 2px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-1px 0px 4px rgba(0, 0, 0, 0.25));
    transform: rotate(45deg);
    top: -10px;
    left: 50%;
    margin-left: -10px;
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
}

.box-content {
    color: #000;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #333;
} */

.ws-story-detailview {
    display: flex;
    min-height: 100vh;
}

.ws-detailview {
    /* background-color: black; */
    flex: 0.7;
    margin-left: 142px;
    margin-right: 32px;
    margin-top: 24px;
    margin-bottom: 40px;
}

.ws-detail-Breadcrumb-Navigation {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.ws-detail-title {
    cursor: pointer;
    padding-top: 24px;
    padding-bottom: 24px;
    align-self: stretch;
    color: #4E4E4E;

    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.ws-detail-authordatebtn {
    display: flex;
}

.ws-detail-authorcdate {
    display: flex;
    flex: 1;
}

.ws-detail-author {
    cursor: pointer;
    padding-bottom: 24px;
    color: #B3B3B3;
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-detail-cdate {
    color: #B3B3B3;
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-detail-hsb {
    display: flex;
}

.ws-detail-heart>img {
    cursor: pointer;
    padding-right: 24px;
    width: 29.395px;
    height: 28px;
}

.ws-detail-share>img {
    cursor: pointer;
    padding-right: 24px;
    width: 28px;
    height: 28px;
}

.ws-detail-bookmark>img {
    cursor: pointer;
    width: 22.573px;
    height: 28px;
}

.ws-detail-summary {
    padding-top: 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid #848484;
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

.ws-detail-story {
    padding-top: 40px;
    padding-bottom: 40px;
    white-space: pre-line;
    align-self: stretch;
    color: var(--black, #000);
    text-align: justify;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ws-detail-magic-pot {
    text-align: center;
    height: 359px;
}

.ws-detail-magic-pot img {
    height: 283px;
    width: 283px;
    display: inline-block;
}

/* .ws-detail-magic-pot-temp {
    text-align: center;
    height: 359px;
} */

/* .ws-detail-magic-pot-temp img {
    height: 283px;
    width: 100%;
    display: inline-block;
} */
.ws-detail-magic-pot-temp {
    position: relative;
    display: inline-block;
}

.ws-detail-magic-pot-temp img {
    width: 100%;
}

.moral-before-click {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 280px;
    background: linear-gradient(180deg, #5259A3 0%, #403085 100%);
    max-width: 90%;
    /* Limit the width to 90% of the parent container */
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Show ellipsis for overflow text */

    /* text */
    color: var(--white, #FFF);

    text-align: center;
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.moral-click {
    position: absolute;
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin: -10px;
    /* width: 300px; */
    /* Set a fixed width */
    /* height: px; */
    /* Set a fixed height to match the width */
    display: flex;
    /* Use flexbox to center the text */
    align-items: center;
    /* Center the text vertically */
    justify-content: center;
    /* Center the text horizontally */

    border-radius: 20px;
    /* Make the element circular */
    background: linear-gradient(180deg, #5259A3 0%, #403085 100%);
    /* white-space: nowrap; */
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Show ellipsis for overflow text */
    white-space: pre-line;
}

.ws-detail-clickhere {
    padding-bottom: 76px;
    /* padding-bottom: 124px; */
    color: #9165D7;
    cursor: pointer;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.2px;
}

.ws-detail-conclusion {
    position: relative;
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    gap: 6px;
    align-self: stretch;
    border-radius: 8px;
    background: #403085;
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.ws-detail-views-rate {
    /* background: #252525; */
    display: flex;
    margin-top: 24px;
    padding-top: 24px;
}

.ws-detail-viewsratebox {
    display: flex;
    /* background: #252525; */
    flex: 1;
}

.ws-detail-rate-story {
    display: flex;
    padding-right: 225px;
}

.ws-detail-rating {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-detail-ratestar {
    padding-left: 16px;

}

.ws-detail-ratestar img {
    cursor: pointer;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.ws-detail-viewsbox {
    display: flex;
    padding-right: 16px;
}

.ws-detail-viewstext {
    padding-right: 8px;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-detail-views {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-detail-ratebox {
    display: flex;
}

.ws-detail-ratetext {
    padding-right: 8px;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-detail-rate {
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ws-stories-detailview-ads {
    flex: 0.3;
}

.ws-stories-new-detailview-adsBlock {
    padding-top: 24px;
    padding-right: 16px;

}

.ws-stories-detailview-adsBlock {
    padding-top: 88px;
}

.ws-detail-storyimage>img {
    object-fit: cover;
    border-radius: 16px;
    height: 714px;
    width: 100%;
}

@media screen and (max-width: 1300px) {

    .ws-detailview {
        flex: 1;
        margin-left: 54px;
        margin-right: 54px;
        margin-top: 24px;
        margin-bottom: 34px;
    }

    .ws-detail-rate-story {
        padding-right: 225px;
    }

    .ws-detail-rating {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-stories-detailview-ads {
        /* background-color: aqua; */
        height: 20vh;
        display: none;
    }

    .ws-detail-storyimage>img {
        object-fit: cover;
        border-radius: 8px;
        height: 353px;
        width: 100%;
    }

    .ws-detail-ratestar img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 950px) {
    .ws-story-detailview {
        display: flex;
        padding-bottom: 60px;
        min-height: 100vh;
    }

    .ws-stories-new-detailview-adsBlock {
        padding-top: 80px;
        padding-right: 0px;

    }

    .ws-detail-title {
        padding-top: 6px;
        padding-bottom: 6px;
        align-self: stretch;
        color: #000;
        font-family: Work Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .ws-detail-author {
        padding-bottom: 16px;
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-cdate {
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-summary {
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #848484;
        align-self: stretch;
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-story {
        padding-top: 8px;
        padding-bottom: 16px;
        white-space: pre-line;
        align-self: stretch;
        color: var(--black, #000);
        text-align: justify;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .ws-detail-magic-pot {
        text-align: center;
        height: 177px;
    }

    .ws-detail-magic-pot img {
        height: 135px;
        width: 135px;
        display: inline-block;
    }

    .ws-detail-clickhere {
        padding-bottom: 0px;
        /* padding-bottom: 124px; */
        color: #9165D7;
        cursor: pointer;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 171.429% */
        letter-spacing: 0.2px;
    }

    .ws-detail-conclusion {
        display: flex;
        padding: 16px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        border-radius: 8px;
        background: #403085;
        color: var(--white, #FFF);
        font-family: Work Sans;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 24px;
        /* 133.333% */
        letter-spacing: 0.2px;
    }

    .ws-detail-views-rate {
        /* background: #252525; */
        display: flex;
        margin-top: 0px;
        /* padding-top: 160px; */
    }

    .speech-bubble {
        padding: 0px 16px;
        left: 60%;
        position: relative;
        background: var(--white, #FFF);
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-1px 0px 4px rgba(0, 0, 0, 0.25));
        /* border-radius: .4em; */
        border-radius: 8px;
    }

    .speech-bubble-container {
        padding-top: 24px;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* Adjust the margin as needed */
    }

    .speech-bubble:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-bottom-color: var(--white, #FFF);
        border-top: 0;
        margin-left: -20px;
        margin-top: -8px;
    }

    .ws-detailview {
        flex: 1;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ws-detail-storyimage>img {
        border-radius: 16px;
        height: 227px;
        width: 100%;
    }

    .ws-detail-heart>img {
        padding-right: 8px;
        width: 16.797px;
        height: 16px;
    }

    .ws-detail-share>img {
        padding-right: 8px;
        width: 16px;
        height: 16px;
    }

    .ws-detail-bookmark>img {
        padding-right: 8px;
        width: 12.899px;
        height: 16px;
    }

    .ws-detail-rate-story {
        padding-right: 0px;
    }

    .ws-detail-rating {
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-viewstext {
        padding-right: 8px;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-views {
        color: var(--text-heading, #252525);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-ratebox {
        display: flex;
    }

    .ws-detail-ratetext {
        padding-right: 8px;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-rate {
        color: var(--text-heading, #252525);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ws-detail-ratestar img {
        margin-right: 4px;
        margin-bottom: 6px;
        width: 8px;
        height: 8px;
    }

    .moral-before-click {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 280px;
        background: linear-gradient(180deg, #5259A3 0%, #403085 100%);
        max-width: 90%;
        /* Limit the width to 90% of the parent container */
        white-space: nowrap;
        /* Prevent text from wrapping to the next line */
        overflow: hidden;
        /* Hide overflow text */
        text-overflow: ellipsis;
        /* Show ellipsis for overflow text */

        /* text */
        color: var(--white, #FFF);

        text-align: center;
        font-family: "Work Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 133.333% */
        letter-spacing: 0.2px;
    }

    .moral-click {
        position: absolute;
        top: 40%;
        left: 70%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.5);
        margin: -10px;
        width: 100px;
        /* Set a fixed width */
        height: 50px;
        /* Set a fixed height to match the width */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: linear-gradient(180deg, #5259A3 0%, #403085 100%);
        overflow: auto;
        /* Enable scrolling when content overflows */
        text-overflow: ellipsis;
        white-space: pre-line;
        /* Hide the scrollbar */
        scrollbar-width: none;
        /* For Firefox */
        -ms-overflow-style: none;
        /* For Internet Explorer and Edge */
        z-index: 1000;
        /* Ensure it's above other elements */

    }
.moral-text{
    margin-top: auto;
}
    .moral-click::-webkit-scrollbar {
        display: none;
        /* For Chrome, Safari, and Opera */
    }


}