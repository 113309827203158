.um-forgot-detail {
    display: flex;
    padding-bottom: 24px;
}

.um-otp-emailbox1 {
    margin-right: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-otp-emailbox2 {
    margin-right: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-otp-emailbox3 {
    margin-right: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-otp-emailbox4 {
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-otp-msg-textbox {
    display: flex;
    padding: 10px 12px;
}

input[type="text"].um-otp-inputbox {
    height: 16px;
    width: 13px;
    padding: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

.um-otp-inputbox::placeholder {
    color: var(--text-secondary, #848484);
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.um-otp-inputbox {
    border: none;
    outline: none;
}

.um-sendotp-disable {
    cursor: pointer;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;
    border-radius: 8px;
    background: var(--text-secondary, #848484);
    color: var(--white, var(--white, #FFF));
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.4px;
}

.um-sendotp-enabled {
    cursor: pointer;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;
    border-radius: 8px;
    background: #403085;
    color: var(--white, var(--white, #FFF));
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.4px;
}