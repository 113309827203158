.um-login {
    display: flex;
    background: white;
    border-radius: 16px 16px 16px 16px;
    text-align: center;
    position: relative;
}

.um-login-banner {
    flex: 0.5;
}

.um-login-img img {
    border-radius: 16px 0px 0px 16px;
    display: block;
    height: 602px;
    width: 624px;
}

.um-login-detail {
    padding-left: 115px;
    padding-right: 116px;
    flex: 0.5;
    display: flex;
    justify-content: center;
    /* Horizontal alignment */
    align-items: center;
    /* Vertical alignment */

}

.um-login-account {
    display: flex;
    justify-content: center;
    /* Horizontal alignment */
    align-items: center;
    /* Vertical alignment */
    padding-bottom: 24px;
    align-self: stretch;
    color: #000;
    text-align: center;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.um-login-social {
    padding-bottom: 16px;
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.um-login-google-fb {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.um-login-facebook {
    cursor: pointer;
    margin-left: auto;
    padding-right: 16px;
}

.um-login-facebook img {
    display: block;
    width: 24px;
    height: 24px;
}

.um-login-google {
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    margin-right: auto;
}

.um-login-google img {
    display: block;
    height: 24px;
    width: 24px;
}

.um-login-email {
    padding-top: 24px;
    padding-bottom: 24px;
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.um-login-emailbox {
    margin-bottom: 8px;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-email-msg-textbox {
    display: flex;
    width: 295px;
    padding: 10px 12px;
}

.um-name-textbox {
    display: flex;
    padding: 10px 12px;
}

.um-name-msg {
    margin-top: auto;
    margin-bottom: auto;
}

.um-name-msg img {
    margin-right: 8px;
    display: block;
    width: 16px;
    height: 16px;
}

.um-eye img {
    display: block;
    height: 16px;
    width: 16px;
}

.input[type="text"].um-name-inputbox {
    padding: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

.um-name-inputbox::placeholder {
    color: var(--text-secondary, #848484);
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.um-name-inputbox {
    border: none;
    outline: none;
}

.um-email-msg {
    margin-top: auto;
    margin-bottom: auto;
}

.um-email-msg img {
    margin-right: 8px;
    display: block;
    width: 16px;
    height: 16px;
}

input[type="email"].um-email-inputbox {
    width: 255px;
    padding: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

.um-email-inputbox::placeholder {
    color: var(--text-secondary, #848484);
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.um-email-inputbox {
    border: none;
    outline: none;
}

.um-login-passwordbox {
    margin-bottom: 8px;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    background: var(--white, #FFF);
}

.um-password-lock-textbox {
    display: flex;
    padding: 10px 12px;
}

.um-password-lock {
    margin-top: auto;
    margin-bottom: auto;
}

.um-password-lock img {
    margin-right: 8px;
    display: block;
    width: 16px;
    height: 16px;
}

.um-password-inputbox {
    outline: none;
    border: none;
}

input[type="password"].um-password-inputbox {
    width: 255px;
    padding: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

input[type="text"].um-password-inputbox {
    width: 255px;
    padding: 0px;
    padding-block: 0px;
    padding-inline: 0px;
}

.um-password-inputbox::placeholder {
    color: var(--text-secondary, #848484);
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}


.forgot-password {
    cursor: pointer;
    padding-bottom: 24px;
    display: flex;
    justify-content: flex-end;
    /* Aligns content to the end horizontally */
    align-items: flex-end;
    /* Aligns content to the end vertically */
    color: var(--text-primary, #474747);
    text-align: right;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    text-decoration-line: underline;
}

.um-login-btn {
    width: 287px;
    cursor: pointer;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #403085;
    color: var(--white, var(--white, #FFF));
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.um-does-signup {
    display: flex;
    padding-top: 24px;
}

.um-account {
    margin-left: auto;
    color: var(--text-primary, #474747);

    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.um-signup-btn {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    color: #403085;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.alert-overlay {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    gap: 4px;
    padding-bottom: 8px;
}

.um-alert-box {
    margin-top: auto;
    margin-bottom: auto;
    color: #E50000;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.alert-box-img {
    margin-top: auto;
    margin-bottom: auto;
}

.alert-box-img img {
    display: block;
    height: 16px;
    width: 16px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    color: #403085;
    ;
    /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Adjust the z-index as needed to ensure it overlays other elements */
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 950px) {
    .um-login-banner {
        display: none;
    }

    .um-login-account {
        font-size: 18px;
    }

    .um-login-detail {
        padding: 24px;
        flex: 0.5;
        display: flex;
        justify-content: center;
        /* Horizontal alignment */
        align-items: center;
        /* Vertical alignment */

    }
}