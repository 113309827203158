@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.AuthorDescriptionCardMainSection {
    width: 760px;
    height: 360px;
    padding: 40px;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.AuthorDescriptionCardFirst {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.AuthorDescriptionCardFirstHead {
    color: var(--text-heading, #252525);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;
}

.AuthorDescriptionCardFirstDesc {
    position: relative;
    border-radius: 10px;
    background: #EFF3F8;
    padding: 9px 16px 7px 16px;
    margin-top: 10px;
    background: #EFF3F8;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.2px;
}

.AuthorDescriptionCardFirstDesc:after {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0%;
    left: 8%;
    content: '';
    margin-top: -10px;
    transform: rotate(45deg);
    background: #EFF3F8;
}

.AuthorDescriptionCardSecond {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-around;
}

.AuthorDescriptionCardSecondHead {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorDescriptionCardSecondInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    margin-top: 4px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorDescriptionCardThird {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.AuthorDescriptionCardThirdHead {
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorDescriptionCardThirdInfo {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
}

.AuthorDescriptionCardThirdInfo span {
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 20px;
    border: 2px solid #403085;
    color: var(--text-primary, #474747);

    /* label medium */
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.4px;
}

#AuthorDescriptionCardSecondInfoEye {
    margin-top: 3px;
    display: block;
    width: 16px;
    height: 16px;
}

#author-img-heart {
    margin-top: 3px;
}

#AuthorDescriptionCardSecondInfoStar img {
    width: 20px;
    height: 20px;
}