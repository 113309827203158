.am-notfound {
    padding: 40px 0px 40px 0px;
    min-height: 55vh;
}

.am-notfound-404 {
    /* padding-top: 292px; */
    padding-top: 100px;
    align-self: stretch;
    color: #403085;
    text-align: center;
    font-family: "Work Sans";
    font-size: 128px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.am-notfoundwhop {
    padding-top: 16px;
    align-self: stretch;
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.am-notfound-page {
    padding-top: 16px;
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.am-return-home {
    cursor: pointer;
    text-align: center;
    color: #403085;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
}

.am-return-btn-text {
    padding-top: 40px;
    gap: 16px;
    display: flex;
    justify-content: center;
}

.am-return-btn {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}

.am-return-btn img {
    display: block;
    height: 24px;
    width: 24px;
}