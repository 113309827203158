.ws-stories-category-navigation {
    padding-left: 24px;
    padding-top: 24px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.sm-stories-subcategory {
    color: #593ECC;
}