.MainPart {
    min-height: 100vh;
}

.MainPart {
    padding: 24px 24px 40px 24px;
}

.cn-Draftbtn {
    padding-bottom: 24px;
}

.CN-Title {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    padding: 24px;
    gap: 16px;
    background: var(--white, #FFF);
}

.cn-title-heading {
    padding-bottom: 16px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-intro {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    padding: 24px;
    gap: 16px;
    background: var(--white, #FFF);
}

.cn-intro-heading {
    display: flex;
    padding-bottom: 16px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-into-count {
    margin-left: auto;
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-mainstory {
    height: 500px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    padding: 24px 24px 24px 24px;
    gap: 16px;
    background: var(--white, #FFF);
}

.cn-mainstory-heading {
    display: flex;
    padding-bottom: 16px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-main-count {
    margin-left: auto;
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-summary {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    padding: 24px;
    gap: 16px;
    background: var(--white, #FFF);
}

.cn-summary-heading {
    display: flex;
    padding-bottom: 16px;
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-moral-count {
    margin-left: auto;
    color: var(--text-secondary, #848484);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}

.cn-submitbtn {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.cn-mainstory textarea {
    border: none;
    resize: none;
    font-family: 'Work Sans', sans-serif;
    height: 450px !important;
    max-height: 450px;
    /* Set a maximum height to prevent expansion */

    /* Set your desired fixed height */
    overflow-y: scroll;
}

.cn-mainstory textarea {
    height: 100%;
    /* Set the desired height */
    overflow-y: auto;
    /* Change 'scroll' to 'auto' to only show scrollbar when necessary */
    display: flex;
    flex-direction: column;
    gap: 24px;
    scrollbar-width: thin;
    border-radius: 8px;
    /* Customize scrollbar width (not supported in Safari) */
    scrollbar-color: #403085 #EAEAEB;
    /* Customize scrollbar color (not supported in Safari) */
}

/* For Webkit-based browsers like Chrome */
.cn-mainstory textarea::-webkit-scrollbar {
    width: 8px;
    /* Customize scrollbar width */
}

/* For Webkit-based browsers like Chrome - Track */
.cn-mainstory textarea::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #EAEAEB;
    /* Set track color */
}

/* For Webkit-based browsers like Chrome - Handle */
.cn-mainstory textarea::-webkit-scrollbar-thumb {
    background-color: #403085;
    /* Set thumb color */
    border-radius: 8px;
    /* Round the thumb */
}

/* For Webkit-based browsers like Safari */
.cn-mainstory textarea::-webkit-scrollbar {
    border-radius: 8px;
    width: 8px;
    /* Customize scrollbar width */
}

/* For Firefox */
.cn-mainstory textarea {
    border-radius: 8px;
    scrollbar-width: thin;
    /* Customize scrollbar width */
    scrollbar-color: #403085 #EAEAEB;
    /* Customize scrollbar color */
}

.cn-mainstory textarea:focus {
    outline-width: 5;
}