.um-forgot {
    display: flex;
    background: white;
    border-radius: 16px 16px 16px 16px;
    position: relative;
}

.um-forgot-detail {
    padding-left: 115px;
    padding-right: 116px;
    flex: 0.5;
    display: flex;
    /* justify-content: center; */
    /* Horizontal alignment */
    align-items: center;
    /* Vertical alignment */

}

.um-forgot-email-text {
    padding-bottom: 4px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.um-forgot-account {
    display: flex;
    /* justify-content: center; */
    /* Horizontal alignment */
    align-items: center;
    /* Vertical alignment */
    padding-bottom: 24px;
    align-self: stretch;
    color: #000;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 51.77px;
}

.um-login-forgot {

    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.um-login-forgot-2 {
    margin-right: auto;
    padding-bottom: 24px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.um-sendotp-btn {
    cursor: pointer;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;
    border-radius: 8px;
    background: #403085;
    color: var(--white, var(--white, #FFF));
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 142.857% */
    letter-spacing: 0.4px;
}

.um-arrow-back-btn {
    display: flex;
    color: #403085;
    cursor: pointer;
    gap: 8px;
    justify-content: center;
    padding-top: 24px;

}

.um-back-arrow-img img {
    display: block;
    height: 20px;
    width: 20px;
}

.um-back-arrow-text {
    text-align: center;
    gap: 8px;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.4px;
}

.um-arrow-back-btn img {
    display: block;
    height: 20px;
    width: 20px;
}

@media screen and (max-width: 950px) {
    .um-forgot-detail {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        flex: 1;
        display: flex;
        /* justify-content: center; */
        /* Horizontal alignment */
        align-items: center;
        /* Vertical alignment */

    }

    .um-forgot-account {
        display: flex;
        /* justify-content: center; */
        /* Horizontal alignment */
        align-items: center;
        /* Vertical alignment */
        padding-bottom: 24px;
        align-self: stretch;
        color: #000;
        font-family: Work Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-right: 0px;
    }
}