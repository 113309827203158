.user-nopublish-img {
    padding-left: 350px;
    padding-right: 350px;
    padding-top: 175px;
}

/* .UserPublishedCard {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.user-nopublish-img img {
    display: block;
    height: 84px;
    width: 84px;
}

.user-nopublish-text {
    color: var(--text-heading, #252525);

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-publish-btn {
    cursor: pointer;
    color: #403085;
    font-weight: 600;
}

.user-nopublish-submit {
    color: var(--text-primary, #474747);

    text-align: center;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorPublicationCardInfoSectionStoryTitleDraft {
    width: 500px;
    display: flex;
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.deleteicon {
    cursor: pointer;
    margin-left: auto;
}

.deleteicon img {
    display: block;
    height: 24px;
    width: 24px;
}

.editicon {
    margin-right: 10px;
    cursor: pointer;
    margin-left: auto;
}

.editicon img {
    display: block;
    height: 24px;
    width: 24px;
}

.EditDelete {
    display: flex;
    margin-left: auto;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.popup-buttons {
    margin-top: 20px;
}

.popup-buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    cursor: pointer;
}