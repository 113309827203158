/* This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-8-2023
Purpose : Preview Part Styling in Contribution Network 
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487 */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.main {
    width: 70%;
    height: 75%;
    z-index: 15;
    margin: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-style: outset;
    display: flex;
    flex-direction: row;
    padding: 24px 16px;
    border-radius: 16px;
    background: var(--white, #FFF);
    gap: 48px;
}

.left {
    width: 50%;
    border: 0.5px solid rgba(0, 0, 0, 0.50);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
}

.left::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width of the scrollbar as needed */
}

.left::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Make the scrollbar thumb transparent */
}

.left::-webkit-scrollbar-track {
    background-color: transparent;
    /* Make the scrollbar track transparent */
}

.right {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    padding: 16px;
}

.cn-preview-box {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    gap: 8px;
}

.cn-img-upload-text {
    margin-top: auto;
    margin-bottom: auto;
}

.cn-img-upload {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    width: 333.4px;
    height: 25.4px;
    gap: 8px;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.2px;
}

.CN-Preview-input {
    margin-right: auto;
    text-align: center;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--Border, #AFB1B6);

    /* width: 100%; */
    display: flex;
    flex-direction: row;
    /* margin: auto; */
    /* height: 10%; */
}

.CN-Preview-input input {
    height: 25.4px;
    width: 333.4px;
    outline: none;
    border: none;
    padding: 0;
    /* width: 100%; */
    resize: none;
    /* padding: 5%; */
    font-size: small;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.2px;
}

.plus-img {
    margin-top: auto;
    margin-bottom: auto;
}

.plus-img img {
    display: block;
    width: 24px;
    height: 24px;
}

.CN-Preview-input textarea {
    width: 100%;
    resize: none;
    padding: 5%;
    font-size: small;
    color: var(--text-secondary, #848484);
    font-family: Work Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.2px;
}

.CN-Preview-input button {
    background-color: white;
    border: none;
    margin: auto;
}

.CN-Preview-input img {
    height: 16px;
    width: 16px;
}

.cn-danger-upload img {
    display: block;
    width: 16px;
    height: 16px;
}

.danger-popup img {
    width: 230.5px;
    height: 73px;
}

.danger-popup {
    position: fixed;
    /* top: 0;
    left: 0; */
    top: -8%;
    left: -14%;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* Adjust z-index to ensure it's above other content */
}

.danger-popup img {
    max-width: 80%;
    /* Adjust the maximum width as needed */
    max-height: 80%;
    /* Adjust the maximum height as needed */
    border-radius: 8px;
    /* Optional: Add rounded corners */
}

.danger-popup p {
    position: absolute;
    color: var(--white, #FFF);
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    /* Adjust the font size as needed */
    /* text-align: center; */
    margin-left: 24px;
    margin-right: 8px;
    margin-top: 10px;
}



.CN-Preview-headings {
    position: relative;
    padding: 8px 16px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    gap: 24px;
    display: flex;
}

.CN-Preview-imageSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 8px 0;
}

.CN-Preview-imageSection img {
    height: 24px;
    width: 24px;
    margin: auto;
}

.CN-Preview-imageSection .browse-Image {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px dashed var(--text-secondary, #848484);
    background: var(--white, #FFF);
    padding: 6px 4px;
    gap: 4px;
}

.CN-Preview-imageSection .browse-Image span {
    margin: 0 10px;
    color: #848484;
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    letter-spacing: 0.2px;
}

.CN-Preview-imageSection .text-Image {
    margin: auto 0;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.2px;
}

.CN-Declaration-Heading {
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
    letter-spacing: 0.2px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
}

.cn-declaration-req {
    color: #B42424;
}

.CN-TNC-Box {
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    /* 121.875% */
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 16px;
}

.CN-TNC-Box div {
    text-align: justify;
    /* word-break: break-all; */
}

.CN-TNC-Box input {
    margin-bottom: auto;
}

.left-upper {
    padding: 8px 24px;
    gap: 5px;
}

.left-lower {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-right: 0;
    justify-content: flex-end;
}

.left-lower button {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
}

.left-lower .submitBtn {
    border-radius: 8px;
    background: #403085;
    color: var(--white, var(--white, #FFF));

    /* label small */
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.left-lower .cancelBtn {
    border-radius: 8px;
    background: var(--primary-subtle, #F7F5FF);
    color: #403085;

    /* label small */
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.right-upper {
    padding-bottom: 24px;
    text-align: center;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 88.889% */
    letter-spacing: 0.2px;
}


.right-lower {
    border-radius: 4px;
    height: 100%;
    max-height: 100%;
    background: var(--white, #FFF);
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
}

.right-lower::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width of the scrollbar as needed */
}

.right-lower::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Make the scrollbar thumb transparent */
}

.right-lower::-webkit-scrollbar-track {
    background-color: transparent;
    /* Make the scrollbar track transparent */
}

.right-lower-upper {
    width: 90%;
    margin: 8px auto;
}

.right-lower-title {
    color: #000;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 4px;
}

.right-lower-author {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

#right-lower-cover-image {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: 1px solid var(--subtle-light, #AFB1B6);
    background: var(--background, #EFEFF0);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* height: 140px; */
    object-fit: contain;
    justify-content: center;
}

#right-lower-cover-image .image-preview {
    width: 100%;
    text-align: center;
}

#right-lower-cover-image img {
    width: 24px;
    height: 24px;
}

#right-lower-cover-image span {
    color: #61646B;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.right-lower-beginning {
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin: 8px 0px;
}

.right-lower-separator {
    height: 1px;
    background: var(--text-secondary, #848484);
    margin-bottom: 8px;
}

.right-lower-story {
    color: var(--black, #000);
    text-align: justify;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.right-lower-message {
    /* color: #000; */
    color: var(--white, #FFF);
    font-family: Poppins;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    /* 300% */
    letter-spacing: 0.2px;
    width: 100%;
    text-align: center;
}

.cn-message-conclusion {
    background: #403085;
    border-radius: 4px;
    margin: 8px;
    padding: 8px;
    text-align: justify;
    /* border-radius: 4px;
    padding: 1% 12px;
    display: inline-flex;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0; */
}

.right-lower-message span {
    background: #403085;
    border-radius: 4px;
    padding: 1% 12px;
    display: inline-flex;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
}

.right-lower-lower {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 2%;
}



.tag {
    display: flex;
    color: var(--white, var(--white, #FFF));
    /* label medium */
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.4px;
}

.tag {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #403085;
    cursor: pointer;
}

.tags-container {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
}

.tagflex {
    display: flex;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media screen and (max-width: 1430px) {
    .CN-Preview-input input {
        height: 25.4px;
        width: 300px;
        outline: none;
        border: none;
        padding: 0;
        /* width: 100%; */
        resize: none;
        /* padding: 5%; */
        font-size: small;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        letter-spacing: 0.2px;
    }

    .cn-img-upload {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        width: 300px;
        height: 25.4px;
        gap: 8px;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        letter-spacing: 0.2px;
    }

}

@media screen and (max-width: 1300px) {
    .CN-Preview-input input {
        height: 25.4px;
        width: 275px;
        outline: none;
        border: none;
        padding: 0;
        /* width: 100%; */
        resize: none;
        /* padding: 5%; */
        font-size: small;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        letter-spacing: 0.2px;
    }

    .cn-img-upload {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        width: 275px;
        height: 25.4px;
        gap: 8px;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        letter-spacing: 0.2px;
    }

}