.am-aboutus-page {
    padding-top: 16px;
    padding-bottom: 72px;
    padding-left: 145px;
    padding-right: 146px;
    min-height: 80vh;
}

.our-story-image-container {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    /* margin-top: -24px; */
    /* height: 100vh; */
    /* Adjust as needed */
}

.our-story-image-container img {
    display: block;
    border-radius: 16px;
    height: 150px;
    width: 150px;
}

.am-aboutus {
    color: var(--black, #000);
    text-align: center;
    font-family: Work Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 66.667% */
    letter-spacing: 0.2px;
    flex-direction: column;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 48px;
}

.am-aboutus-underline {
    padding: 4px 0px 4px 0px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    text-decoration-line: underline
}

.am-aboutus-heading-underline {
    padding: 18px 0px 18px 0px;
    margin-left: -25px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    text-decoration-line: underline
}

.am-aboutus-heading {
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 75% */
    letter-spacing: 0.2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
}

.am-para {
    padding: 2px 0px 2px 0px;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.am-aboutus-team {
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.am-aboutus-one {
    padding-left: 96px;
    padding-right: 96px;
}

.am-aboutus-para {
    display: flex;
}

.app-logo>img {
    margin-top: 10px;
    height: 140px
}

.kushmanda-logo>img {
    height: 150px
}

@media screen and (max-width: 1200px) {
    .am-aboutus-page {
        padding-top: 16px;
        padding-bottom: 24px;
        padding-left: 54px;
        padding-right: 54px;
    }
}

@media screen and (max-width: 950px) {
    .kushmanda-logo>img {
        width: 49px;
        height: 54px;
    }

    .kushmanda-logo {

        margin-top: auto;
        margin-bottom: auto;
    }

    .app-logo {
        margin-top: auto;
        margin-bottom: auto;
    }

    .app-logo>img {
        margin-top: 10px;
        width: 49px;
        height: 54px;
    }

    .am-aboutus {
        color: var(--black, #000);
        text-align: center;
        font-family: Work Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 66.667% */
        letter-spacing: 0.2px;
        flex-direction: column;
        justify-content: center;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .am-aboutus-heading {
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 75% */
        letter-spacing: 0.2px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
    }

    .am-para {
        padding: 2px 0px 2px 0px;
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .am-aboutus-page {
        padding-top: 0px;
        padding-bottom: 16px;
        margin-bottom: 200px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .am-aboutus-one {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');