/* HamburgerMenu.css */
@media screen and (max-width: 950px) {
    .header {
        display: flex;
        justify-content: space-between;
        /* Space out logo, title, and hamburger */
        align-items: center;
        /* Center items vertically */
        padding: 10px 20px;
        /* Add some padding */
        /* background: #f9f9f9; */
        /* Example background color */
    }

    .menu-data {
        padding-top: 40px;
    }

    .menu-data button {
        background: none;
        border: none;
        color: #000;
        /* color: #fff; */
        font-size: 18px;
        /* Adjust the font size */
        padding: 10px;
        text-align: left;
        cursor: pointer;
    }

    .logo-container .logo {
        width: 27px;
        /* Adjust the logo width */
        height: 27px;
        /* Example size, adjust as needed */
    }

    .title {
        flex-grow: 1;
        /* Allows the title to fill the space */
        text-align: center;
        /* Center the title text */
        color: var(--black, #000);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .menu-icon {
        cursor: pointer;
        font-size: 24px;
        /* Adjust size as needed */
    }

    .menu {
        z-index: 1000;
        width: 0;
        border-radius: 0px 0px 32px 0px;
        height: 100vh;
        /* Full viewport height */
        position: fixed;
        /* Fixed to the viewport */
        right: 0;
        top: 0;
        background-color: var(--white, #FFF);
        /* background: #f9f9f9; */
        overflow-x: hidden;
        transition: width 0.3s;
    }

    .show {
        width: 201px;
        /* Width of the menu when shown */
    }

    .menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .menu ul li {
        padding: 8px 16px;
        /* border-bottom: 1px solid #ddd; */
    }

    .menu ul li div {
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.08px;
        display: block;
    }

    .header-am {
        padding: 24px 0px 24px 0px;
    }

    .header-am div {
        padding: 8px 16px;
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.08px;
        display: block;
    }

    .header-app-store {
        padding-bottom: 24px;
    }

    .header-app-store div {
        padding: 8px 16px;
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.08px;
    }

    .header-app-store div img {
        width: 100px;
        height: 31px;
    }

    .header-follow-us {
        padding: 8px 16px;
        color: var(--black, #000);
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.08px;
    }

    .social-media {
        padding: 8px 0px 0px 0px;
    }

    .social-logo img {
        height: 20px;
        width: 20px;
    }

    .header-rights {
        margin-top: 20vh;
        padding: 8px 16px;
        color: var(--text-secondary, #848484);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: 0.08px;
    }

    .close-icon {
        font-size: 16px;
        /* Adjust size as needed */
        position: absolute;
        right: 10px;
        /* Adjust for padding and appearance */
        top: 10px;
        /* Adjust for padding and appearance */
        cursor: pointer;
        display: block;
        /* Ensure it's always visible when menu is open */
    }



    .ws-header-nav-mob {
        display: flex;
        gap: 12px;
        padding: 16px;
    }

    .ws-written-btn.active,
    .ws-audio-btn.active,
    .ws-video-btn.active

    /* Add other tabs with .active as needed */
        {
        border-radius: 8px;
        background: #BDBFE0;
    }

    .ws-written-btn {
        display: flex;
        gap: 4px;
        padding: 6px;
        font-size: 12px;
        border-radius: 8px;
        border: 1px solid #BDBFE0;
        background: var(--white, #FFF);
    }

    .ws-written-icon {
        margin-top: auto;
        margin-bottom: auto;
    }

    .ws-written-icon img {
        display: block;
        width: 16px;
        height: 16px;
    }

    .ws-audio-btn {
        display: flex;
        gap: 4px;
        font-size: 12px;
        padding: 6px;
        border-radius: 8px;
        border: 1px solid #BDBFE0;
        background: var(--white, #FFF);
    }

    .ws-audio-icon {
        margin-top: auto;
        margin-bottom: auto;
    }

    .ws-audio-icon img {
        display: block;
        width: 16px;
        height: 16px;
    }

    .ws-audio-text {
        margin-top: auto;
        margin-bottom: auto;
    }

    .ws-video-btn {
        display: flex;
        gap: 4px;
        padding: 6px;
        font-size: 12px;
        border-radius: 8px;
        border: 1px solid #BDBFE0;
        background: var(--white, #FFF);
    }

    .ws-video-icon {
        margin-top: auto;
        margin-bottom: auto;
    }

    .ws-video-icon img {
        display: block;
        width: 16px;
        height: 16px;
    }

    .ws-video-text {
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* Rest of your styles */