.um-profile-popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.um-profile-popupContent {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    gap: 40px;
}

.um-userprofile {
    display: flex;
}

.um-user-partone {
    padding-right: 40px;
}

.um-editbtn.active,
.um-socialbtn.active {
    cursor: pointer;
    margin-bottom: 8px;
    align-items: center;
    display: flex;
    gap: 8px;
    color: #403085;
    font-weight: 500;
    font-family: 'Work sans';
    font-size: 18px;
    border-radius: 24px;
    border: 2px solid #403085;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding: 12px 16px 12px 16px;
}

.um-editbtn img {
    display: block;
    height: 20px;
    width: 20px;
}

.um-editbtn,
.um-socialbtn {
    cursor: pointer;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-family: 'Work sans';
    font-size: 18px;
    border-radius: 24px;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding: 12px 16px 12px 16px;
}

.um-socialbtn img {
    display: block;
    height: 20px;
    width: 20px;
}

.um-deletebtn {
    cursor: pointer;
    gap: 8px;
    border-top: 0.5px solid #00000080;
    display: flex;
    align-self: center;
    align-items: center;
    color: #F63E3E;
    font-weight: 400;
    font-family: 'Work sans';
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.6px;
    padding: 12px 16px 12px 16px;
}

.um-deletebtn img {
    display: block;
    height: 20px;
    width: 20px;
}

.um-user-parttwo {
    width: 411px;
    height: 600px;
    gap: 24px;
}

.um-user-partthree {
    width: 411px;
    height: 600px;
    gap: 24px;
}

.um-profile-head {
    margin-bottom: 16px;
    font-size: 32px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.2px;
}

.um-profile-avatar {
    margin-bottom: 16px;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.um-profile-name {
    margin-bottom: 16px;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.um-userprofile-img {
    margin-bottom: 24px;
    display: flex;
}

.um-userprofile-img-upload {
    margin-right: 40px;
}

.um-img-upload-text {
    text-align: center;
    padding: 12px 8px;
    border: 1px solid #403085;
    border-radius: 8px;
    color: #403085;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-userprofile-uploadtext {
    font-family: 'Work Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 18.77px;
}

.um-userprofile-upload {
    margin-bottom: 16px;
}

.um-uploadtext {
    margin-top: 16px;
    margin-bottom: 16px;
}

.um-userprofile-img-upload img {
    display: block;
    height: 134px;
    width: 134px;
    border-radius: 100px;
}

.um-profile-bio {
    margin-bottom: 16px;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.um-profile-savebtn {
    cursor: pointer;
    text-align: center;
    padding: 12px 8px 12px 8px;
    border-radius: 8px;
    background: #403085;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-profile-nametext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-profile-nametext input {
    width: 379px;
    background: #FBFAFF;
    border: none;
    outline: none;
}

.um-profile-nametext img {
    display: block;
    height: 16px;
    width: 16px;

}

.um-twitter-nametext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-twitter-nametext input {
    width: 379px;
    background: #FBFAFF;
    border: none;
    outline: none;
}

.um-twitter-nametext input::placeholder {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-twitter-nametext img {
    display: block;
    height: 14px;
    width: 14px;

}

.um-linkedin-nametext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-linkedin-nametext input {
    width: 379px;
    background: #FBFAFF;
    border: none;
    outline: none;
}

.um-linkedin-nametext input::placeholder {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-linkedin-nametext img {
    display: block;
    height: 14px;
    width: 14px;

}

.um-instagram-nametext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-instagram-nametext input {
    width: 379px;
    background: #FBFAFF;
    border: none;
    outline: none;
}

.um-instagram-nametext input::placeholder {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-instagram-nametext img {
    display: block;
    height: 14px;
    width: 14px;

}

.um-facebook-nametext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-facebook-nametext input {
    width: 379px;
    background: #FBFAFF;
    border: none;
    outline: none;
}

.um-facebook-nametext input::placeholder {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-facebook-nametext img {
    display: block;
    height: 14px;
    width: 14px;

}

.um-profile-biotext {
    margin-bottom: 24px;
    display: flex;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 8px;
    background: #FBFAFF;
    box-shadow: 0px 1px 4px 0px #00000040;

}

.um-profile-biotext textarea {
    background: #FBFAFF;
    text-decoration: none;
    width: 343px;
    height: 84px;
    border: none;
    outline: none;
}

.um-profile-biotext textarea::placeholder {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
}

.um-profile-biotext img {
    display: block;
    height: 16px;
    width: 16px;
}