.cn-authorlist {
    min-height: 100vh;
}

.cn-authorlist-page {
    display: flex;
}

.cn-author-part {
    flex: 0.8;
    padding: 0px 24px 0px 24px;
}

.cn-authorlist-ads {
    flex: 0.2;
}

.cn-author-fav-discover {
    display: flex;
}

.cn-author-fav {
    cursor: pointer;
    flex: 1;
    padding-right: 12px;
}

.cn-image-container {
    position: relative;
}

.cn-image-container img {
    height: 372px;
    width: 100%;
}



.image-text {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 24px;
}

.cn-arrowright {
    display: block;
    padding-left: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.cn-fav-text {
    color: var(--white, #FFF);
    font-family: Work Sans;
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
}

.cn-arrowright img {
    display: block;
    height: 48px;
    width: 48px;
}

.cn-author-discover {
    cursor: pointer;
    flex: 1;
    padding-left: 12px;
}

.cn-new-community-part {
    padding: 40px 110px 40px 0px;
}

.cn-new-community-part-2 {
    padding: 0px 110px 40px 0px;
}

.cn-new-community-part-3 {
    padding: 0px 110px 40px 0px;
}

.cn-new-community-header {
    display: flex;
    padding-bottom: 24px;
}

.cn-new-community-text {
    margin-right: auto;
    color: #000;
    font-family: Work Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.6px;
}

.cn-new-show-all {
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    color: var(--text-heading, #252525);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.cn-new-author {
    display: flex;
}

.cn-author-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    padding-bottom: 16px;
}

.cn-author-img img {
    display: block;
    margin-top: -67.5px;
    height: 160px;
    width: 160px;
    border-radius: 100px;
}

.cn-author-followed {
    border-radius: 16px;
    margin-top: 67.5px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 16px;
    background: #FBFAFF;
}

.cn-new-author-followed-card {
    cursor: pointer;

    padding: 0px 40px 0px 40px;
}

.cn-new-author-card {
    cursor: pointer;
    border-radius: 16px;
    background: #FBFAFF;
    padding: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cn-new-author-card-2 {
    cursor: pointer;
    margin-left: 24px;
    border-radius: 16px;
    background: #FBFAFF;
    padding: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cn-new-author-card-3 {
    cursor: pointer;
    margin-left: 24px;
    border-radius: 16px;
    background: #FBFAFF;
    padding: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cn-new-author-card-4 {
    cursor: pointer;
    margin-left: 24px;
    border-radius: 16px;
    background: #FBFAFF;
    padding: 40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.author-img {
    padding-bottom: 24px;
}

.author-img img {
    display: block;
    height: 213px;
    width: 213px;
    border-radius: 100px;
}

.cn-follow-author-name {
    text-align: center;
    padding-bottom: 8px;
    color: #000;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.cn-author-name {
    padding-bottom: 8px;
    color: #000;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.cn-follow-author-design {
    text-align: center;
    padding-bottom: 16px;
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cn-author-design {
    padding-bottom: 24px;
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cn-author-design-2 {
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cn-author-design-3 {
    align-self: stretch;
    color: var(--text-primary, #474747);
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cn-author-follow-inside {
    display: flex;
    gap: 8px;
}

.cn-author-follow {
    display: flex;
    padding: 8px 12px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #403085;
}

.cn-author-favorite {
    margin-top: 8px;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--primary-subtle, #F7F5FF);
}


.cn-favorite-author-add {
    margin-right: auto;
}

.cn-favorite-author-add img {
    display: block;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
}

.cn-author-add img {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
}

.cn-add-favorite {
    color: #403085;

    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
    width: max-content;
}

.cn-add-follow {
    color: var(--white, var(--white, #FFF));
    /* label small */
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.6px;
}

.cn-author-bottom {
    padding: 0px 24px 40px;
}

.cn-author-writing {
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: #FBFAFF;
}

.cn-author-new-write {
    width: 620px;
}

.cn-web-logo {
    display: flex;
    padding-bottom: 24px;
}

.cn-logo img {
    height: 46px;
    width: 46px;
}

.cn-logo-wiki {
    color: #403085;

    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.cn-logo-name {
    padding-left: 16px;
    margin-top: auto;
    margin-bottom: auto;
    color: var(--black, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.cn-author-write-text {
    padding-bottom: 16px;
    width: 620px;
    color: #403085;
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.6px;
}

.cn-author-write-text-two {
    padding-bottom: 24px;
    color: #403085;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.cn-author-write-btn {
    cursor: pointer;
    display: flex;
    /* width: 620px; */
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 86px;
    border-radius: 8px;
    background: #403085;
    flex: 1 0 0;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.cn-authorlist-detailview-adsBlock {
    padding-right: 24px;
}

@media screen and (max-width: 1535px) {
    .cn-new-author-card-4 {
        display: none;
    }
}

@media screen and (max-width: 950px) {
    .cn-author-part {
        padding-bottom: 20px;
    }

    .cn-author-fav-discover {
        display: block;
    }

    .cn-authorlist-page {
        display: block;
    }

    .cn-author-fav {
        cursor: pointer;
        flex: 1;
        padding-right: 0px;
        padding-bottom: 16px;
    }

    .author-card-container {
        padding-bottom: 4px;
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .author-card-container::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    .author-card-container::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }

    .cn-image-container img {
        height: 159px;
        width: 100%;
    }

    .cn-author-discover {
        cursor: pointer;
        flex: 1;
        padding-left: 0px;
    }

    .cn-arrowright img {
        display: block;
        height: 16px;
        width: 16px;
    }

    .cn-new-community-text {
        font-size: 16px;
    }


    .cn-fav-text {
        color: var(--white, #FFF);
        font-family: Work Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.6px;
        text-transform: capitalize;
    }

    .cn-new-community-part {
        padding: 40px 0px 40px 0px;
    }

    .author-img {
        padding-bottom: 16px;
    }

    .author-img img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: 88px;
        width: 88px;
        border-radius: 100px;
    }

    .cn-author-followed {
        border-radius: 16px;
        margin-top: 67.5px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 16px;
        background: #FBFAFF;
    }

    .cn-author-img img {
        display: block;
        margin-top: -45.5px;
        height: 88px;
        width: 88px;
        border-radius: 100px;
    }

    .cn-new-author-card {
        cursor: pointer;
        border-radius: 16px;
        background: #FBFAFF;
        padding: 16px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .cn-author-design {
        padding-bottom: 24px;
        align-self: stretch;
        color: var(--text-primary, #474747);
        font-family: Work Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .cn-new-author-followed-card {
        cursor: pointer;

        padding: 0px 0px 0px 0px;
    }

    .cn-follow-author-name {
        font-size: 14px;
    }

    .cn-author-name {
        padding-bottom: 8px;
        color: #000;
        font-family: Work Sans;
        font-size: 14px;
        width: max-content;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
    }

    .cn-author-follow-inside {
        display: flex;
        gap: 4px;
    }

    .cn-author-follow {
        display: flex;
        padding: 2px 12px;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: #403085;
    }

    .cn-author-favorite {
        margin-top: 8px;
        display: flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--primary-subtle, #F7F5FF);
    }

    .cn-add-favorite {
        color: #403085;

        /* label small */
        font-family: Work Sans;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
        letter-spacing: 0.6px;
        width: max-content;
    }

    .cn-author-add img {
        display: flex;
        width: 13px;
        height: 13px;
        justify-content: center;
        align-items: center;
    }

    .cn-new-show-all {
        font-size: 12px;
    }

    .cn-new-community-part-2 {
        padding: 0px 0px 40px 0px;
    }
}