.sm-stories-search {
    min-height: 100vh;
}

.sm-stories-box-title {
    cursor: pointer;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.search-result {
    padding-left: 24px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.no-results {
    padding-left: 24px;
    color: var(--black, #000);
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

@media screen and (max-width: 950px) {
    .sm-stories-search {
        min-height: 100vh;
        padding-bottom: 80px;
    }
}