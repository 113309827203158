.AuthorSocialMediaCardMainSection {
    width: 200px;
    height: 163px;
    border-radius: 16px;
    background: var(--white, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
}

.AuthorSocialMediaCardMainSectionFirst span {
    color: var(--text-primary, #474747);
    text-align: center;
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.AuthorSocialMediaCardMainSectionSecond span {
    color: var(--text-heading, #252525);
    text-align: center;
    font-family: Work Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}

.AuthorSocialMediaCardMainSectionThird {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
}

#linkedinSocialMediaAuthor {
    cursor: pointer;
    border-radius: 4px;
}

#fbSocialMediaAuthor {
    cursor: pointer;
    border-radius: 4px;
}

#instSocialMediaAuthor {
    cursor: pointer;
    border-radius: 4px;
}

#twitterSocialMediaAuthor {
    cursor: pointer;
    border-radius: 4px;
}

#linkedinSocialMediaAuthordisable {
    cursor: not-allowed;
    border-radius: 4px;
}

#fbSocialMediaAuthordisable {
    cursor: not-allowed;
    border-radius: 4px;
}

#instSocialMediaAuthordisable {
    cursor: not-allowed;
    border-radius: 4px;
}

#twitterSocialMediaAuthordisable {
    cursor: not-allowed;
    border-radius: 4px;
}